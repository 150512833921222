import { Component, OnInit, Optional } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthClass } from '@aws-amplify/auth/lib-esm/Auth';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { AuthService } from '../../../services/authservice/auth.service';
import { WarningDialogComponent } from '../../dialogs/warning-dialog/warning-dialog.component';

@Component({
	selector: 'dl-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
	public logginIn = false;
	public isDialog: boolean;

	loginForm = new FormGroup({
		username: new FormControl('', [Validators.minLength(4)]),
		password: new FormControl('', [Validators.minLength(4)]),
		remember: new FormControl('')
	});

	constructor(
		private dialog: MatDialog,
		private router: Router,
		private route: ActivatedRoute,
		private authService: AuthService,
		@Optional() private dialogRef: MatDialogRef<LoginComponent>
	) {
		this.isDialog = !!dialogRef;
	}

	ngOnInit(): void {}

	public forgot(): void {
		this.router.navigate(['requestNew'], { relativeTo: this.route.parent });
	}

	public login(): void {
		const auth: AuthClass = this.authService.Auth;

		if (this.loginForm.valid) {
			this.logginIn = true;
			if (!!this.loginForm.controls['remember'].value) {
				localStorage.setItem('rememberMe', 'true');
				auth.configure({
					storage: localStorage
				});
			} else {
				localStorage.setItem('rememberMe', 'false');
				auth.configure({
					storage: sessionStorage
				});
			}

			auth.signIn(this.loginForm.controls['username'].value, this.loginForm.controls['password'].value)
				.then((session: CognitoUser) => {
					this.logginIn = false;

					const sess: any = session;
					if (!!sess.challengeName && sess.challengeName === 'NEW_PASSWORD_REQUIRED') {
						this.authService.User = session;
						this.router.navigate(['..', 'changePassword'], { relativeTo: this.route });
						return;
					}

					if (!!this.dialogRef) {
						this.dialogRef.close();
					} else {
						this.router.navigate(['/']);
					}
				})
				.catch((err) => {
					if (err.code === 'PasswordResetRequiredException') {
						this.router.navigate(['forgot'], { relativeTo: this.route.parent });
					} else {
						this.dialog.open(WarningDialogComponent, {
							width: '400px',
							data: {
								description: err.message
							}
						});
					}

					this.logginIn = false;
				});
		}
	}
}
