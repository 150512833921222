import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/apiservice/api.service';

@Component({
	selector: 'dl-spla-config-customer-user-view',
	templateUrl: './customer-user-view.component.html',
	styleUrls: ['./customer-user-view.component.scss']
})
export class SplaConfigCustomerUserViewComponent implements OnInit, AfterViewInit {
	public users: User[] = [];
	public userView: User[] = [];
	public customer: string;

	private filterProductiveUsers: boolean = false;

	constructor(private router: Router, private route: ActivatedRoute, private apiService: ApiService) {}

	async ngOnInit(): Promise<void> {
		this.route.params.subscribe(async (params) => {
			this.customer = params.customer;
			this.users = (
				await this.apiService.get<{ customer: string }, User[]>('spla/users', { customer: this.customer })
			).data;

			this.filterProductiveUsersChange(this.filterProductiveUsers);
		});
	}

	ngAfterViewInit(): void {}

	async selectUser(id: string): Promise<void> {
		await this.router.navigate([id], { relativeTo: this.route });
	}

	filterProductiveUsersChange(enabled: boolean): void {
		this.filterProductiveUsers = enabled;

		if (this.filterProductiveUsers) {
			this.userView = this.users.filter((user) => user.products > 0);
		} else {
			this.userView = this.users;
		}
	}

	filterChange(event: MatCheckboxChange): void {
		this.filterProductiveUsersChange(event.checked);
	}
}

interface User {
	displayname: string;
	id: string;
	products: number;
}
