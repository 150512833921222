import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/apiservice/api.service';
import { productNames } from './product-names';

@Component({
	selector: 'dl-spla-individual-user-view',
	templateUrl: './individual-user-view.component.html',
	styleUrls: ['./individual-user-view.component.scss']
})
export class SplaIndividualUserViewComponent implements OnInit, AfterViewInit {
	public customer: string;
	public user: string;

	public userInformation: UserInformation;

	public displayedColumns = ['name', 'reason'];

	public userProducts: any[] = [];

	constructor(private router: Router, private route: ActivatedRoute, private apiService: ApiService) {}

	ngOnInit(): void {
		this.route.params.subscribe(async (params) => {
			this.customer = params.customer;
			this.user = params.user;

			this.userInformation = (
				await this.apiService.get<{ customer: string; user: string }, UserInformation>('spla/user', {
					customer: this.customer,
					user: this.user
				})
			).data;

			this.userProducts = Object.keys(this.userInformation.Products).map((key) => ({
				name: productNames[key],
				reason: this.userInformation.Products[key]
			}));
		});
	}

	ngAfterViewInit(): void {}

	async customerClicked(id: string): Promise<void> {
		await this.router.navigate([id], { relativeTo: this.route });
	}

	async userViewClicked(): Promise<void> {
		await this.router.navigate(['users'], { relativeTo: this.route });
	}
}

export interface UserInformation {
	Displayname: string;
	Id: string;
	IsExcludedForReporting: boolean;
	IsActive: boolean;
	Products: {
		[pid: string]: string[];
	};
}
