<div class="spla-customer-container">
	<div class="spacer height-limited">
		<div fxFlexFill fxLayout="column" fxLayoutGap="10px">
			<mat-card class="mat-elevation-z8">
				<mat-card-title>
					{{ report }} for {{ customer }}
				</mat-card-title>
				<mat-card-content fxLayout="column">
					<table mat-table [dataSource]="products" class="mat-elevation-z8" style="margin-top: 20px;">
						<ng-container matColumnDef="product">
							<th mat-header-cell *matHeaderCellDef> Product </th>
							<td mat-cell *matCellDef="let element"> {{element.product}} </td>
							<td mat-footer-cell *matFooterCellDef style="font-weight: bold;"> Total </td>
						</ng-container>

						<ng-container matColumnDef="number">
							<th mat-header-cell *matHeaderCellDef> Userlicenses </th>
							<td mat-cell *matCellDef="let element"> {{element.count}} </td>
							<td mat-footer-cell *matFooterCellDef> </td>
						</ng-container>

						<ng-container matColumnDef="provisioned">
							<th mat-header-cell *matHeaderCellDef> Provisioned </th>
							<td mat-cell *matCellDef="let element"> {{+element.measurementConfig.beistellungen}} </td>
							<td mat-footer-cell *matFooterCellDef> </td>
						</ng-container>

						<ng-container matColumnDef="sum">
							<th mat-header-cell *matHeaderCellDef> Total </th>
							<td mat-cell *matCellDef="let element" style="font-weight: bold;">
								{{+element.measurementConfig.beistellungen + element.count }}
							</td>
							<td mat-footer-cell *matFooterCellDef style="font-weight: bold;"> {{getTotalCost()}} </td>
						</ng-container>

						<ng-container matColumnDef="price">
							<th mat-header-cell *matHeaderCellDef> Price </th>
							<td mat-cell *matCellDef="let element"> {{+element.price}} </td>
							<td mat-footer-cell *matFooterCellDef> </td>
						</ng-container>

						<tr mat-header-row *matHeaderRowDef="['product', 'number', 'provisioned', 'price', 'sum']"></tr>
						<tr mat-row
							*matRowDef="let row; columns: ['product', 'number', 'provisioned', 'price', 'sum'];"></tr>
						<tr mat-footer-row *matFooterRowDef="['product', 'number', 'provisioned', 'price', 'sum']"></tr>
					</table>
				</mat-card-content>
			</mat-card>
		</div>
	</div>
</div>