import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

export const routes: Routes = [
	{
		path: 'SPLA ActiveDirectory Report',
		loadChildren: () => import('./subreports/spla-report/spla-reports.module').then((m) => m.SplaReportsModule)
	},
	{
		path: 'Netapp Reports',
		loadChildren: () => import('./subreports/netapp-report/netapp-reports.module').then((m) => m.NetappReportsModule)
	},
	{
		path: 'Citrix Reports',
		loadChildren: () => import('./subreports/citrix-report/citrix-reports.module').then((m) => m.CitrixReportsModule)
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class ReportsRoutingModule { }
