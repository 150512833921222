<div class="spla-customer-container">
	<div class="spacer">
		<mat-card class="mat-elevation-z8 fill-and-limit" fxFlex="1 1 0" fxLayout="column">
			<mat-card-title>
				Kunden
			</mat-card-title>
			<mat-card-content class="fill-and-limit" fxFlex="1 1 0">
				<mat-action-list style="height: 100%; overflow-y: scroll">
					<button *ngFor="let customer of customers" mat-list-item
						(click)="customerClicked(customer.name)">{{customer.longname}}</button>
				</mat-action-list>
			</mat-card-content>
		</mat-card>
	</div>
</div>