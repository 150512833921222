import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/apiservice/api.service';

@Component({
	selector: 'dl-spla-config-add-report',
	templateUrl: './add-report.component.html',
	styleUrls: ['./add-report.component.scss']
})
export class SplaConfigAddReportComponent implements OnInit, AfterViewInit {
	public reports: ReportsResponse[] = [];
	public customer: string;
	public addProductForm: FormGroup;

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private apiService: ApiService,
		private formBuilder: FormBuilder
	) {
		this.addProductForm = this.formBuilder.group({
			report: ['', Validators.required]
		});
	}

	async ngOnInit(): Promise<void> {
		this.route.params.subscribe((params) => {
			this.customer = params.customer;
		});

		this.reports = (
			await this.apiService.get<any, ReportsResponse[]>('spla/reports', { context: 'CUSTOMER' })
		).data;
	}

	ngAfterViewInit(): void {}

	async customerClicked(id: string): Promise<void> {
		await this.router.navigate([id], { relativeTo: this.route });
	}

	async userViewClicked(): Promise<void> {
		await this.router.navigate(['users'], { relativeTo: this.route });
	}

	async addReport(): Promise<void> {
		const request = {
			context: 'CUSTOMER',
			customer: this.customer,
			name: this.addProductForm.get('report').value.name,
			configuration: this.addProductForm.get('productConfig')?.value
		};

		console.log(request);

		await this.apiService.post('spla/addReport', request);
	}

	async cancel(): Promise<void> {
		await this.router.navigate(['..'], { relativeTo: this.route });
	}

	reportSelectionChange(event: MatSelectChange): void {
		if (!!this.addProductForm.get('productConfig')) {
			this.addProductForm.removeControl('productConfig');
		}

		const productSchema: SchemaDefintion[] | undefined = event.value.schema;

		if (!productSchema) {
			return;
		}

		const configBuilder = {};
		productSchema.forEach((schema) => {
			switch (schema.type) {
				case 'string':
					configBuilder[schema.name] = ['', Validators.required];
			}
		});

		this.addProductForm.addControl('productConfig', this.formBuilder.group(configBuilder));
	}
}

interface ReportsResponse {
	name: string;
	description: string;
	schema: SchemaDefintion[];
}

type SchemaDefintion = StringSchemaDefintion;

interface BaseSchema {
	name: string;
	readable: string;
}

interface StringSchemaDefintion extends BaseSchema {
	type: 'string';
}
