import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/apiservice/api.service';

@Component({
	selector: 'dl-spla-config-add-product',
	templateUrl: './add-product.component.html',
	styleUrls: ['./add-product.component.scss']
})
export class SplaConfigAddProductComponent implements OnInit, AfterViewInit {
	public product = '';
	public customers: Customer[] = [];
	public contracts: Contract[] = [];
	public measurementMethod: string[] = [];
	public measurementInfo: MeasurementInfo = undefined;

	contractForm: FormGroup;
	productConfigForm: FormGroup;

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private formBuilder: FormBuilder,
		private apiService: ApiService
	) {
		this.contractForm = this.formBuilder.group({
			customer: ['', Validators.required],
			contract: ['', Validators.required]
		});

		this.productConfigForm = this.formBuilder.group({
			manual: ['0', Validators.required],
			beistellungen: ['0', [Validators.required, Validators.pattern(/.*/g)]],
			reportMicrosoft: [true],
			reportCustomer: [true],
			measurementMethod: ['', Validators.required]
		});
	}

	async ngOnInit(): Promise<void> {
		this.route.params.subscribe((params) => {
			this.product = params.product;
		});

		this.customers = (await this.apiService.get<{}, Customer[]>('spla/customers')).data;
		this.contracts = (await this.apiService.get<{}, Contract[]>('spla/contracts')).data;
	}

	ngAfterViewInit(): void {}

	async onStepperUpdate(event: StepperSelectionEvent): Promise<void> {
		if (event.selectedIndex === 1) {
			this.measurementMethod = (
				await this.apiService.get<any, string[]>('spla/measurementMethod', {
					product: this.product,
					contract: this.contractForm.get('contract').value
				})
			).data;
		} else if (event.selectedIndex === 2) {
			const { measurementConfig, ...commonConfig } = this.productConfigForm.value;
			console.log({
				product: this.product,
				config: {
					common: commonConfig,
					measurement: measurementConfig
				},
				...this.contractForm.value
			});
			await this.apiService.post('spla/products/add', {
				product: this.product,
				config: {
					common: commonConfig,
					measurement: measurementConfig
				},
				...this.contractForm.value
			});
		}
	}

	async changeMeasurementMethod(event: MatSelectChange): Promise<void> {
		const method: string = event.value;
		const measurementInfo = (await this.apiService.get<any, MeasurementInfo>('spla/measurementInfo', { method }))
			.data;

		if (!!this.productConfigForm.get('measurementConfig')) {
			this.productConfigForm.removeControl('measurementConfig');
		}

		if (!measurementInfo.schema) {
			this.measurementInfo = undefined;
			return;
		}

		const configBuilder = {};
		measurementInfo.schema.forEach((schema) => {
			switch (schema.type) {
				case 'string':
					configBuilder[schema.name] = ['', Validators.required];
			}
		});

		this.productConfigForm.addControl('measurementConfig', this.formBuilder.group(configBuilder));
		this.measurementInfo = measurementInfo;
	}

	async applyChanges(): Promise<void> {}
}

interface MeasurementInfo {
	description: string;
	schema: SchemaDefintion[];
}

type SchemaDefintion = StringSchemaDefintion;

interface BaseSchema {
	name: string;
	readable: string;
}

interface StringSchemaDefintion extends BaseSchema {
	type: 'string';
}

interface Customer {
	name: string;
}

interface Contract {
	name: string;
}
