import { FormGroup, FormControl } from '@angular/forms';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'dl-spla-config-optimizer',
	templateUrl: './optimizer.component.html',
	styleUrls: ['./optimizer.component.scss'],
})
export class SplaConfigOptimizerComponent implements OnInit, AfterViewInit {
	public configurationOptions: FormGroup;

	public type: string;
	public server: string;

	constructor(private route: ActivatedRoute) {
		this.configurationOptions = new FormGroup({
			optimizer: new FormControl('graphflow'),
			cost: new FormControl('price')
		});
	}

	ngOnInit(): void {
		this.route.params.subscribe(params => {
			this.type = params.type;
			this.server = params.server;
		});
	}

	ngAfterViewInit(): void {
	}
}
