import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DateService } from 'src/app/services/dateservice/date.service';

@Component({
	selector: 'dl-spla-dashboard-date',
	templateUrl: './dashboard-date.component.html',
	styleUrls: ['./dashboard-date.component.scss'],
})
export class SplaDashboardDateComponent implements OnInit, AfterViewInit {
	public monthMap: { value: string, name: string }[] = [
		{ value: '01', name: 'January' },
		{ value: '02', name: 'February' },
		{ value: '03', name: 'March' },
		{ value: '04', name: 'April' },
		{ value: '05', name: 'May' },
		{ value: '06', name: 'June' },
		{ value: '07', name: 'July' },
		{ value: '08', name: 'August' },
		{ value: '09', name: 'September' },
		{ value: '10', name: 'October' },
		{ value: '11', name: 'November' },
		{ value: '12', name: 'December' }
	];
	public years: number[] = [2020];

	public month: string;
	public year: number;

	constructor(private router: Router, private route: ActivatedRoute, private dateService: DateService) {
		console.log(this.dateService.getCurrentMonth());
		console.log(this.dateService.getCurrentYear());
	}

	ngOnInit(): void {

	}

	ngAfterViewInit(): void {
		this.month = this.dateService.getMonth();
		this.year = this.dateService.getYear();
	}

	public changeMonth(): void {
		console.log('old: ' + this.dateService.getMonth());
		this.dateService.setMonth(this.month);
		console.log('new: ' + this.dateService.getMonth());
	}

	public changeYear(): void {
		this.dateService.setYear(this.year);
		console.log(this.dateService.getYear());
	}
}
