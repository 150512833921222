<div class="spla-customer-container">
	<div class="spacer height-limited">
		<div fxFlexFill fxLayout="column" fxLayoutGap="10px">
			<mat-card class="mat-elevation-z8">
				<mat-card-title>
					Add Report for {{ customer }}
				</mat-card-title>
				<mat-card-content>
					<form [formGroup]="addProductForm">
						<div fxLayout="column" fxLayoutGap="20px" style="margin-top: 20px">
							<mat-form-field>
								<mat-label>Reporttyp</mat-label>
								<mat-select formControlName="report"
									placeholder="Select a report type to add to the customer"
									(selectionChange)="reportSelectionChange($event)" #reportSelector>
									<mat-option *ngFor="let report of reports" [value]="report">
										{{ report.name }}
									</mat-option>
								</mat-select>
							</mat-form-field>
							<span *ngIf="reportSelector.value">{{ reportSelector.value.description }}</span>
							<div fxLayout="column" fxLayoutGap="20px" *ngIf="reportSelector.value"
								formGroupName="productConfig">
								<ng-container *ngFor="let config of reportSelector.value.schema">
									<ng-container *ngIf="config.type === 'string'">
										<mat-form-field>
											<mat-label>{{ config.readable }}</mat-label>
											<input matInput formControlName="{{ config.name }}" required>
										</mat-form-field>
									</ng-container>
								</ng-container>
							</div>

							<div>
								<button [disabled]="!addProductForm.valid" (click)="addReport()" mat-raised-button
									color="primary" style="float: right; margin-left: 10px">
									Add Report
								</button>
								<button mat-stroked-button color="accent" style="float: right"
									(click)="cancel()">Cancel</button>
							</div>
						</div>
					</form>

				</mat-card-content>
			</mat-card>
		</div>
	</div>
</div>