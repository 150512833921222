<div class="spla-customer-container">
    <div class="spacer">
        <mat-card class="mat-elevation-z8">
            <mat-card-title>
                <span>
                    <a [routerLink]="['../../']" style="text-decoration: none; color: rgba(0,0,0,.87)">Dashboard</a>
                    <span> > </span>
                    <span>{{ customer }}</span>
                </span>
                <button style="float: right" mat-stroked-button color="primary">
                    Export
                </button>
            </mat-card-title>
            <mat-card-content style="padding-top: 10px">
                <table mat-table [dataSource]="license" style="width: 100%">
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef>Name</th>
                        <td mat-cell *matCellDef="let element">{{ element.name }}</td>
                    </ng-container>
                    <ng-container matColumnDef="count">
                        <th mat-header-cell *matHeaderCellDef>Count</th>
                        <td mat-cell *matCellDef="let element">{{ element.count }}</td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="licenseClick(row.name)"
                        class="table-row"></tr>
                </table>
            </mat-card-content>
        </mat-card>
    </div>
</div>
