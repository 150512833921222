import { Component, OnInit } from '@angular/core';
import {
	FormControl,
	FormGroup,
	FormGroupDirective,
	NgForm,
	ValidationErrors,
	ValidatorFn,
	Validators
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../services/authservice/auth.service';
import { WarningDialogComponent } from '../../dialogs/warning-dialog/warning-dialog.component';

export const passwordMatchValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
	const password1 = control.get('password1').value;
	const password2 = control.get('password2').value;

	return password1 === password2 ? null : { passwordmatch: true };
};

class CrossFieldErrorMatcher implements ErrorStateMatcher {
	isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
		return control.dirty && form.invalid;
	}
}

@Component({
	selector: 'dl-change-password',
	templateUrl: './changepassword.component.html',
	styleUrls: ['./changepassword.component.scss']
})
export class ChangePasswordComponent implements OnInit {
	public changing: boolean = false;
	public verifyPassword = new CrossFieldErrorMatcher();

	public newPasswordForm = new FormGroup(
		{
			oldpassword: new FormControl('', [
				Validators.minLength(8),
				Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/g)
			]),
			password1: new FormControl('', [
				Validators.minLength(8),
				Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/g)
			]),
			password2: new FormControl('')
		},
		{ validators: passwordMatchValidator }
	);

	constructor(
		private dialog: MatDialog,
		private router: Router,
		private route: ActivatedRoute,
		private authService: AuthService,
		private snackBar: MatSnackBar,
		private dialogRef: MatDialogRef<ChangePasswordComponent>
	) {}

	async ngOnInit(): Promise<void> {}

	public backToLogin(): void {
		this.router.navigate(['login'], { relativeTo: this.route.parent });
	}

	public cancel(): void {
		this.dialogRef.close();
	}

	public async changePassword(): Promise<void> {
		if (this.newPasswordForm.controls['password1'].value !== this.newPasswordForm.controls['password2'].value) {
			this.dialog.open(WarningDialogComponent, {
				width: '400px',
				data: {
					description: 'Passwords do not match'
				}
			});
			return;
		}

		const user = await this.authService.Auth.currentAuthenticatedUser();

		this.authService.Auth.changePassword(
			user,
			this.newPasswordForm.get('oldpassword').value,
			this.newPasswordForm.get('password1').value
		)
			.then(() => {
				this.snackBar.open('Successfully changed password', 'Dismiss', {
					duration: 5000,
					panelClass: ['green-snackbar']
				});
				this.dialogRef.close();
			})
			.catch((err) => {
				this.dialog.open(WarningDialogComponent, {
					width: '400px',
					data: {
						description: err.message
					}
				});
			});
	}
}
