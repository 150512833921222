<mat-card-header>
	<mat-card-title>
		<h3 style="display: flex; justify-content: flex-start;">
			<mat-icon style="margin-right: 5px">vpn_key</mat-icon>
			<span style="margin-left: 10px">Forgot Password</span>
		</h3>
	</mat-card-title>
</mat-card-header>
<mat-card-content>
	<div style="margin: 10px 60px">
		<form [formGroup]="forgotPasswordForm" #form="ngForm" fxFlex="grow" fxLayout="column" fxLayoutGap="15px"
			fxLayoutAlign="center stretch" (ngSubmit)="requestNewPassword()">
			<div fxFlex="0 0 auto" fxLayout="column" fxLayoutGap="15px">

				<mat-form-field style="width: 100%">
					<mat-icon matPrefix style="margin-right: 5px; margin-top: -5px">lock</mat-icon>
					<input matInput type="text" placeholder="Username" required formControlName="username">
					<mat-error *ngIf="forgotPasswordForm.controls['username'].hasError('email')">
						Please enter your email.
					</mat-error>
				</mat-form-field>
				<button type="submit" style="display:none" [disabled]="forgotPasswordForm.invalid || changing">hidden
					submit</button>
			</div>
		</form>
	</div>
</mat-card-content>
<mat-card-actions>
	<button mat-raised-button color="primary" style="margin-left: 20px" (click)="backToLogin()">Back</button>
	<div style="float: right; margin-right: 20px" matTooltip="Please enter valid data in the login form above"
		[matTooltipDisabled]="forgotPasswordForm.valid">
		<button mat-raised-button color="primary" [disabled]="forgotPasswordForm.invalid || changing"
			(click)="requestNewPassword()">
			<div fxLayout="row" fxFlexAlign="center">
				<div style="padding: 5.5px 11px 5.5px 0" *ngIf="changing">
					<mat-progress-spinner color="primary" mode="indeterminate" diameter="25"></mat-progress-spinner>
				</div>
				<span>Request new password</span>
			</div>
		</button>
	</div>
</mat-card-actions>