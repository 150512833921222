import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiResponse, ApiService } from 'src/app/services/apiservice/api.service';
import { LicenseService } from 'src/app/services/licenseservice/license.service';

@Component({
	selector: 'dl-spla-dashboard-customer',
	templateUrl: './customer.component.html',
	styleUrls: ['./customer.component.scss']
})
export class SplaDashboardCustomerComponent implements OnInit, AfterViewInit {
	public customer: string;

	public displayedColumns: string[] = ['name', 'count'];
	public license: LicenseInformation[];

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private apiService: ApiService,
		private licenseService: LicenseService
	) { }


	ngOnInit(): void {
		this.route.params.subscribe((params) => {
			this.customer = params.customer;
		});
		this.getLicensesforCustomer(this.customer).then((response: CustomerLicenseResponse) => {
			this.license = this.licenseService.getLicenseMap()
				.map((pair) => ({ count: response[pair.name], name: pair.readable }))
				.filter((item) => item.count > 0);
		});
	}

	ngAfterViewInit(): void { }

	async licenseClick(license: string): Promise<void> {
		await this.router.navigate([license], { relativeTo: this.route });
	}

	async getLicensesforCustomer(customer: string, date?: number): Promise<CustomerLicenseResponse> {
		return await this.apiService
			.get<{ customer: string; date?: number }, CustomerLicenseResponse>('spla/ad/products', date ? { customer, date } : { customer })
			.then((response: ApiResponse<CustomerLicenseResponse>) => response.data);
	}
}

export interface LicenseInformation {
	name: string;
	count: number;
}

export interface CustomerLicenseResponse {
	citrix_base: number;
	citrix_base_customers_key: string;
	citrix_kauf_base: number;
	citrix_kauf_base_customers_key: string;
	citrix_kauf_premium: number;
	citrix_kauf_premium_customers_key: string;
	citrix_premium: number;
	citrix_premium_customers_key: string;
	customer: string;
	desktop: number;
	desktop_customers_key: string;
	exchange_enterprise: number;
	exchange_enterprise_customers_key: string;
	exchange_enterprise_plus: number;
	exchange_enterprise_plus_customers_key: string;
	exchange_standard: number;
	exchange_standard_customers_key: string;
	exchange_standard_plus: number;
	exchange_standard_plus_customers_key: string;
	ms_office: number;
	ms_office_customers_key: string;
	ms_office_pro: number;
	ms_office_pro_customers_key: string;
	ms_office_std: number;
	ms_office_std_customers_key: string;
	project: number;
	project_customers_key: string;
	sharepoint_enterprise: number;
	sharepoint_enterprise_customers_key: string;
	sharepoint_foundation: number;
	sharepoint_foundation_customers_key: string;
	sharepoint_std: number;
	sharepoint_std_customers_key: string;
	visio: number;
	visio_customers_key: string;
	windows: number;
	windows_customers_key: string;
}
