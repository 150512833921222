<div class="spla-customer-container">
	<div fxFlex="column" class="spacer height-limited">
		<div fxFlexFill fxLayout="column" fxLayoutGap="10px" class="height-limited">
			<mat-card fxLayout="column" fxFlex="0 1 auto" class="mat-elevation-z8 height-limited">
				<mat-card-title fxFlex="0 0 auto">
					Users (Count: {{ userView.length }})
					<mat-checkbox style="float: right" value="" color="primary" (change)="filterChange($event)">At
						least
						one license</mat-checkbox>
				</mat-card-title>
				<mat-card-content fxFlex="0 0 95%" style="overflow-y: scroll">
					<mat-action-list>
						<button *ngFor="let user of userView" mat-list-item
							(click)="selectUser(user.id)">{{ user.displayname }}</button>
					</mat-action-list>
				</mat-card-content>

			</mat-card>
		</div>
	</div>
</div>