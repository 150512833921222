<div class="spla-customer-container">
    <div class="spacer">
        <mat-card>
            <mat-card-title>Customers > {{ customer }} </mat-card-title>
            <mat-card-content style="margin-top: 20px;">
                <form [formGroup]="configurationOptions">
                    <h2 style="padding: 20px 0px 10px 0px;">ActiveDirectory</h2>
                    <div fxLayout="row" fxLayoutGap="20px">
                        <div fxLayout="column" fxLayoutGap="40px" fxFlex="1 1 20%">
                            <p style="font-size: large; text-align: right; padding-right: 40px;">Citrix</p>
                            <p style="font-size: large; text-align: right; padding-right: 40px;">Sharepoint</p>
                            <p style="font-size: large; text-align: right; padding-right: 40px;">Exchange</p>
                            <p style="font-size: large; text-align: right; padding: 20px 40px 20px 40px;">ActiveDirectory</p>
                            <p style="font-size: large; text-align: right; padding-right: 40px;" class="ou-label">OU</p>
                            <p style="font-size: large; text-align: right; padding-right: 40px;">Report Type</p>
                        </div>
                        <div fxLayout="column" fxLayoutGap="40px" fxFlex="1 1 80%">
                            <mat-radio-group [formControl]="citrix">
                                <mat-radio-button value="kb">Kauf Base</mat-radio-button>
                                <mat-radio-button value="b">Base</mat-radio-button>
                                <mat-radio-button value="p">Premium</mat-radio-button>
                                <mat-radio-button value="kp">Kauf Premium</mat-radio-button>
                                <mat-radio-button value="n">None</mat-radio-button>
                            </mat-radio-group>
                            <mat-radio-group [formControl]="sharepoint">
                                <mat-radio-button value="standard">Standard</mat-radio-button>
                                <mat-radio-button value="enterprise">Enterprise</mat-radio-button>
                                <mat-radio-button value="none">None</mat-radio-button>
                            </mat-radio-group>
                            <mat-radio-group [formControl]="exchange">
                                <mat-radio-button value="standard">Standard</mat-radio-button>
                                <mat-radio-button value="enterprise">Enterprise</mat-radio-button>
                                <mat-radio-button value="none">None</mat-radio-button>
                            </mat-radio-group>
                            <mat-form-field>
                                <input matInput value="example.pironet-ndh.com">
                            </mat-form-field>
                            <mat-form-field class="ou-input">
                                <input matInput value="1001,1002">
                            </mat-form-field>
                            <mat-form-field class="report-type-input">
                                <mat-select [formControl]="reportType">
                                    <mat-option value="standard">Standard</mat-option>
                                    <mat-option value="daas">DaaS</mat-option>
                                    <mat-option value="NCG">NCG</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>

                    <mat-divider></mat-divider>
                    <h2 style="padding: 20px 0px 10px 0px;">VMWare / HyperV</h2>

                    <div fxLayout="row" fxLayoutGap="20px">
                        <div fxLayout="column" fxLayoutGap="40px" fxFlex="1 1 20%">
                            <p style="font-size: large; text-align: right; padding-right: 40px;">Prefix-Regex</p>
                        </div>
                        <div fxLayout="column" fxLayoutGap="40px" fxFlex="1 1 80%">
                            <mat-form-field class="prefix-input">
                                <input matInput value="$hlsm\-.*$">
                            </mat-form-field>
                        </div>
                    </div>
                </form>
                
            </mat-card-content>
        </mat-card>
    </div>
</div>
