<div class="spla-config-container">
	<div class="spacer" fxLayout="column" fxLayoutGap="10px">
		<mat-card class="mat-elevation-z8">
			<mat-card-title>Products</mat-card-title>
			<mat-card-content>
				<mat-list>
					<ng-container *ngFor="let product of products">
						<mat-divider></mat-divider>
						<mat-list-item>
							<div fxFill fxLayout="row" fxLayoutAlign="space-between center">
								<div fxFlex="0 0 auto">
									<mat-icon *ngIf="!!product.description" color="primary"
										[matTooltip]="product.description" style="font-size: 15px; user-select: none;">
										help</mat-icon>
									<div *ngIf="!!!product.description"
										style="width: 24px; height: 24px; display: inline-block"></div>
									<span style="margin-left: 20px">{{ product.name }}</span>
								</div>

								<button mat-stroked-button color="primary" fxFlex="0 0 auto"
									(click)="productClicked(product)">
									Zu Kunde hinzufügen
								</button>
							</div>
						</mat-list-item>
					</ng-container>
				</mat-list>
			</mat-card-content>
		</mat-card>
	</div>
</div>