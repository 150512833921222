import { FormGroup, FormControl } from '@angular/forms';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'dl-spla-config-customer',
	templateUrl: './customer.component.html',
	styleUrls: ['./customer.component.scss'],
})
export class SplaConfigCustomerComponent implements OnInit, AfterViewInit {
	public citrix = new FormControl('n');
	public sharepoint = new FormControl('standard');
	public exchange = new FormControl('enterprise');
	public reportType = new FormControl('standard');

	public configurationOptions: FormGroup;

	public customer: string;

	constructor(private route: ActivatedRoute) {
		this.configurationOptions = new FormGroup({
			citrix: new FormControl('n'),
			reportType: new FormControl('standard')
		});
	}

	ngOnInit(): void {
		this.route.params.subscribe(params => {
			this.customer = params.customer;
		});
	}

	ngAfterViewInit(): void {
	}
}
