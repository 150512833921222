import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { AuthGuard } from '../../auth/auth.guard';

/**
 * Data with nested structure.
 * Each node has a name and an optional list of children.
 */
interface DataNode {
	name: string;
	path?: string;
	children?: DataNode[];
}

const TREE_DATA: DataNode[] = [
	{
		name: 'Data',
		children: [
			{
				name: 'Infrastructure',
				children: [
					{ name: 'VMWare', path: '/data/infrastructure/vmware' },
					{ name: 'HyperV', path: '/data/infrastructure/hyperv' },
					{ name: 'NetApp' }
				]
			}
		]
	},
	{
		name: 'Doku'
	},
	{
		name: 'Customers',
		path: '/customers/list'
	},
	{
		name: 'Reports',
		children: [
			{
				name: 'ActiveDirectory Report',
				children: [
					{
						name: 'SPLA ActiveDirectory Report',
						children: [
							{
								name: 'Customers',
								path: '/reports/SPLA ActiveDirectory Report/customers'
							},
							{
								name: 'Microsoft',
								path: '/reports/SPLA ActiveDirectory Report/reports/microsoft'
							},
							{
								name: 'Small Medium Large',
								path: '/reports/SPLA ActiveDirectory Report/reports/smallmediumlarge'
							}
						]
					}
				]
			}, {
				name: 'Netapp Reports',
				children: [
					{
						name: 'Storage',
						children: [
							{
								name: 'HLSM DB 01 - 11',
								path: '/reports/Netapp Reports/Storage/dashboard'
							},
						]
					}
				]

			}, {
				name: 'Citrix Reports',
				children: [
					{
						name: 'LUI',
						children: [
							{
								name: 'UserReport',
								path: '/reports/Citrix Reports/LUI/dashboard'
							},
						]
					}
				]

			}
			// { name: 'Dashboard', path: '/spla/dashboard' }
			// {
			// 	name: 'Order Form',
			// 	children: [
			// 		{
			// 			name: 'Default',
			// 			path: '/spla/order/default'
			// 		},
			// 		{
			// 			name: 'DEU'
			// 		},
			// 		{
			// 			name: 'Academic',
			// 			path: '/spla/order/academic'
			// 		}
			// 	]
			// },
			// {
			// 	name: 'Config',
			// 	children: [
			// 		{
			// 			name: 'Products',
			// 			path: '/spla/config/products'
			// 		},
			// 		{
			// 			name: 'Customers',
			// 			path: '/spla/config/customers'
			// 		}
			// 	]
			// }
		]
	},
	// {
	// 	name: 'Grid Demo',
	// 	children: [
	// 		{
	// 			name: 'ag-Grid',
	// 			path: '/grid-demo/ag-grid-demo'
	// 		}
	// 	]
	// }
];

@Component({
	selector: 'dl-navigation-panel',
	templateUrl: './navigation-panel.component.html',
	styleUrls: ['./navigation-panel.component.scss']
})
export class NavigationPanelComponent implements OnInit {
	public loggedIn = false;
	public userName: Observable<string>;

	public treeControl = new NestedTreeControl<DataNode>((node) => node.children);
	public dataSource = new MatTreeNestedDataSource<DataNode>();

	constructor(
		private authGuard: AuthGuard,
		private iconRegistry: MatIconRegistry,
		private domSanitizer: DomSanitizer
	) {
		authGuard.LoggedIn.subscribe((state: boolean) => (this.loggedIn = state));
		this.userName = authGuard.UserName;

		this.dataSource.data = TREE_DATA;

		// iconRegistry.addSvgIcon('database', domSanitizer.bypassSecurityTrustResourceUrl('assets/database.svg'));
	}

	ngOnInit(): void { }

	hasChild = (_: number, node: DataNode) => !!node.children && node.children.length > 0;
}
