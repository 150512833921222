import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiResponse, ApiService } from 'src/app/services/apiservice/api.service';

@Component({
	selector: 'dl-spla-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss']
})
export class SplaDashboardComponent implements OnInit, AfterViewInit {
	public customerConfigs: CustomerConfigResponse = { customers: [] };
	public vmwareStandard: number;
	public vmwareDatacenter: number;
	public hypervStandard: number;
	public hypervDatacenter: number;

	constructor(private router: Router, private route: ActivatedRoute, private apiService: ApiService) {}

	async ngOnInit(): Promise<void> {
		this.getCustomerConfig().then((response: CustomerConfigResponse) => (this.customerConfigs = response));

		this.getVmWareLicenses().then((response: LicenseResponse) => {
			this.vmwareStandard = response.standard;
			this.vmwareDatacenter = response.datacenter;
		});

		this.getHypervLicenses().then((response: LicenseResponse) => {
			this.hypervStandard = response.standard;
			this.hypervDatacenter = response.datacenter;
		});
	}

	ngAfterViewInit(): void {}

	async customerClicked(customer: string): Promise<void> {
		await this.router.navigate(['customer', customer], { relativeTo: this.route });
	}

	async vmDetailsClicked(type: string): Promise<void> {
		await this.router.navigate(['vmdetails', type], { relativeTo: this.route });
	}

	async getCustomerConfig(): Promise<CustomerConfigResponse> {
		return await this.apiService
			.get<{ key: string }, CustomerConfigResponse>('keying/configKey', { key: 'SPLA' })
			.then((response: ApiResponse<CustomerConfigResponse>) => response.data);
	}

	async getVmWareLicenses(): Promise<LicenseResponse> {
		return await this.apiService
			.get<{}, LicenseResponse>('spla/vmware/licenses')
			.then((response: ApiResponse<LicenseResponse>) => response.data);
	}

	async getHypervLicenses(): Promise<LicenseResponse> {
		return await this.apiService
			.get<{}, LicenseResponse>('spla/hyperv/licenses')
			.then((response: ApiResponse<LicenseResponse>) => response.data);
	}

	async exportAll(): Promise<void> {
		await this.apiService.download<{ format: string }>('spla/ad/exportAll', 'SPLAReport.csv', {
			format: 'text/csv'
		});
	}
}

export interface ConfigKey<T> {
	customer: string;
	key: 'SPLA';
	parameters: T;
}

export interface SplaInfo {
	citrix_licbase: boolean;
	citrix_licpremium: boolean;
	sharepoint_foundation_lic: boolean;
}

export type CustomerConfig = ConfigKey<SplaInfo>;

export interface CustomerConfigResponse {
	customers: CustomerConfig[];
}

export interface LicenseResponse {
	standard: number;
	datacenter: number;
}
