import { Component, OnInit } from '@angular/core';
import {
	FormControl,
	FormGroup,
	FormGroupDirective,
	NgForm,
	ValidationErrors,
	ValidatorFn,
	Validators
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../services/authservice/auth.service';
import { WarningDialogComponent } from '../../dialogs/warning-dialog/warning-dialog.component';

export const passwordMatchValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
	const password1 = control.get('password1').value;
	const password2 = control.get('password2').value;

	return password1 === password2 ? null : { passwordmatch: true };
};

class CrossFieldErrorMatcher implements ErrorStateMatcher {
	isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
		return control.dirty && form.invalid;
	}
}

@Component({
	selector: 'dl-reset-password',
	templateUrl: './newpassword.component.html',
	styleUrls: ['./newpassword.component.scss']
})
export class NewPasswordComponent implements OnInit {
	public changing: boolean = false;
	public verifyPassword = new CrossFieldErrorMatcher();

	public newPasswordForm = new FormGroup(
		{
			password1: new FormControl('', [
				Validators.minLength(8),
				Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/g)
			]),
			password2: new FormControl('')
		},
		{ validators: passwordMatchValidator }
	);

	constructor(
		private dialog: MatDialog,
		private router: Router,
		private route: ActivatedRoute,
		private authService: AuthService
	) {}

	async ngOnInit(): Promise<void> {
		if (!this.authService.User) {
			this.router.navigate(['..', 'login'], { relativeTo: this.route });
		}
	}

	public backToLogin(): void {
		this.router.navigate(['login'], { relativeTo: this.route.parent });
	}

	public changePassword(): void {
		if (this.newPasswordForm.controls['password1'].value !== this.newPasswordForm.controls['password2'].value) {
			this.dialog.open(WarningDialogComponent, {
				width: '400px',
				data: {
					description: 'Passwords do not match'
				}
			});
			return;
		}

		this.authService.User.completeNewPasswordChallenge(
			this.newPasswordForm.controls['password1'].value,
			(this.authService.User as any).challengeParam.requiredAttributes,
			{
				onFailure: (err) => {
					this.dialog.open(WarningDialogComponent, {
						width: '400px',
						data: {
							description: err.message
						}
					});
				},
				onSuccess: (session) => {
					this.router.navigate(['login'], { relativeTo: this.route.parent });
				}
			}
		);
	}
}
