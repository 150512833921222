<div class="spla-dashboard-container">
	<div class="spacer" fxFlexFill fxLayout="column">
		<div fxFlexFill fxLayout="column" fxLayoutGap="10px">
			<mat-card class="mat-elevation-z8" fxFlex="0 0 auto">
				<mat-card-title>VMWARE <button style="float: right;" mat-stroked-button color="primary"
						(click)="vmDetailsClicked('vmware')">View details</button></mat-card-title>
				<mat-card-content>
					<div style="margin-top: 30px;" fxLayout="row">
						<div fxFlex="1 0 auto" fxLayout="column" fxLayoutAlign="center">
							<div [countUp]="vmwareStandard"
								style="text-align: center; font-size: xx-large; margin-bottom: 16px;"
								[reanimateOnClick]="false">
								0
							</div>
							<div style="text-align: center; font-size: x-large;">
								Standard
							</div>
						</div>
						<div fxFlex="1 0 auto" fxLayout="column" fxLayoutAlign="center">
							<div [countUp]="vmwareDatacenter"
								style="text-align: center; font-size: xx-large; margin-bottom: 16px;"
								[reanimateOnClick]="false">
								0
							</div>
							<div style="text-align: center; font-size: x-large;">
								Datacenter
							</div>
						</div>
					</div>
				</mat-card-content>
			</mat-card>
			<mat-card class="mat-elevation-z8" fxFlex="0 0 auto">
				<mat-card-title>HYPERV <button style="float: right;" mat-stroked-button color="primary"
						(click)="vmDetailsClicked('hyperv')">View details</button></mat-card-title>
				<mat-card-content>
					<div style="margin-top: 30px;" fxLayout="row">
						<div fxFlex="1 0 auto" fxLayout="column" fxLayoutAlign="center">
							<div [countUp]="hypervStandard"
								style="text-align: center; font-size: xx-large; margin-bottom: 16px;"
								[reanimateOnClick]="false">
								0
							</div>
							<div style="text-align: center; font-size: x-large;">
								Standard
							</div>
						</div>
						<div fxFlex="1 0 auto" fxLayout="column" fxLayoutAlign="center">
							<div [countUp]="hypervDatacenter"
								style="text-align: center; font-size: xx-large; margin-bottom: 16px;"
								[reanimateOnClick]="false">
								0
							</div>
							<div style="text-align: center; font-size: x-large;">
								Datacenter
							</div>
						</div>
					</div>
				</mat-card-content>
			</mat-card>
			<mat-card class="mat-elevation-z8 fill-and-limit" fxFlex="1 1 0" fxLayout="column">
				<mat-card-title>
					User Software
					<button style="float: right" mat-stroked-button color="primary" (click)="exportAll()">
						Export
					</button>
				</mat-card-title>
				<mat-card-content class="fill-and-limit" fxFlex="1 1 0">
					<mat-action-list style="height: 100%; overflow-y: scroll">
						<button *ngFor="let customer of customerConfigs.customers" mat-list-item
							(click)="customerClicked(customer.customer)">{{customer.customername}}</button>
					</mat-action-list>
				</mat-card-content>
			</mat-card>
		</div>
	</div>
</div>