<div class="spla-customer-container">
	<div class="spacer height-limited">
		<div fxFlexFill fxLayout="column" fxLayoutGap="10px">
			<mat-card class="mat-elevation-z8">
				<mat-card-title>
					Kundencenter
				</mat-card-title>
				<mat-card-content>
					<mat-action-list>
						<button mat-list-item>Produkte</button>
						<button mat-list-item (click)="userViewClicked()">Nutzer</button>
					</mat-action-list>
				</mat-card-content>
			</mat-card>
			<mat-card class="mat-elevation-z8">
				<mat-card-title>
					Reports configured for customer
					<button mat-stroked-button style="float: right" color="primary" (click)="addReport()">
						Add Report
					</button>
				</mat-card-title>
				<mat-card-content>
					<mat-action-list>
						<button mat-list-item *ngIf="reports.length == 0" (click)="addReport()">
							No reports configured. Click here to get started
						</button>
						<button mat-list-item *ngFor="let report of reports"
							(click)="viewReport(report)">{{ report.name }} </button>
					</mat-action-list>
				</mat-card-content>
			</mat-card>
		</div>
	</div>
</div>