import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HypervComponent } from './hyperv/hyperv.component';
import { VmwareComponent } from './vmware/vmware.component';

const routes: Routes = [
  {
    path: 'vmware',
    component: VmwareComponent
  }, {
    path: 'hyperv',
    component: HypervComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class InfrastructureRoutingModule { }
