import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/apiservice/api.service';

@Component({
	selector: 'dl-spla-config-view-report',
	templateUrl: './view-report.component.html',
	styleUrls: ['./view-report.component.scss']
})
export class SplaConfigViewReportComponent implements OnInit, AfterViewInit {
	public customer: string;
	public report: string;

	public products: Product[] = [];

	constructor(private router: Router, private route: ActivatedRoute, private apiService: ApiService) {}

	async ngOnInit(): Promise<void> {
		this.route.params.subscribe(async (params) => {
			this.customer = params.customer;
			this.report = params.report;

			this.products = (
				await this.apiService.get<any, Product[]>('spla/reports/customer/report', {
					report: this.report,
					customer: this.customer
				})
			).data;
		});
	}

	ngAfterViewInit(): void {}

	getTotalCost() {
		return this.products.reduce((acc, value) => acc + value.count, 0);
	}
}

interface Product {
	contract: string;
	count: number;
	measurementConfig: {
		beistellungen: string;
	};
	price: string;
	product: string;
}
