import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatTreeModule } from '@angular/material/tree';
import { TableVizComponent } from './table-viz/table-viz.component';

@NgModule({
  declarations: [TableVizComponent],
  exports: [
    TableVizComponent
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatListModule,
    MatIconModule,
    MatTreeModule,
    MatRippleModule,
    MatButtonModule,
    MatTableModule,
    MatPaginatorModule
  ]
})
export class VisualizationModule { }
