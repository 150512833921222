import { ActivatedRoute, Router } from '@angular/router';
import { AfterViewInit, Component, OnInit } from '@angular/core';

@Component({
	selector: 'dl-spla-config',
	templateUrl: './config.component.html',
	styleUrls: ['./config.component.scss'],
})
export class SplaConfigComponent implements OnInit, AfterViewInit {


	constructor(private router: Router, private route: ActivatedRoute) {
	}

	ngOnInit(): void {
	}

	ngAfterViewInit(): void {
	}

	async customerClicked(customer: string) {
		await this.router.navigate(['customer', customer], { relativeTo: this.route });
	}

	async optimizerClicked(type: string, server: string) {
		await this.router.navigate(['optimizer', type, server], { relativeTo: this.route });
	}
}
