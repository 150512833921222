import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/apiservice/api.service';

@Component({
	selector: 'dl-spla-config-product',
	templateUrl: './product.component.html',
	styleUrls: ['./product.component.scss']
})
export class SplaConfigProductComponent implements OnInit, AfterViewInit {
	public products: Product[] = [];

	constructor(private router: Router, private route: ActivatedRoute, private apiService: ApiService) {}

	async ngOnInit(): Promise<void> {
		this.products = (await this.apiService.get<{}, Product[]>('spla/products')).data;
	}

	ngAfterViewInit(): void {}

	async productClicked(product: Product) {
		await this.router.navigate([product.name], { relativeTo: this.route });
	}
}

interface Product {
	name: string;
	type: string;
	description?: string;
}
