import { Injectable } from '@angular/core';
import moment, { Moment } from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class DateTimeService {
	private _reportMonth: BehaviorSubject<Moment>;

	constructor() {
		const parsed: Moment = moment(sessionStorage.getItem('reportMonth'));
		if (parsed.isValid()) {
			this._reportMonth = new BehaviorSubject<Moment>(parsed);
		} else {
			this._reportMonth = new BehaviorSubject<Moment>(moment());
		}
	}

	get reportMonth(): Observable<Moment> {
		return this._reportMonth.asObservable();
	}

	get currentReportMonth(): Moment {
		return this._reportMonth.value;
	}

	setReportMonth(date: Moment): void {
		sessionStorage.setItem('reportMonth', date.format('YYYY-MM'));
		this._reportMonth.next(date);
	}
}
