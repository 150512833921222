import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CustomerListComponent } from './components/customer-list/customer-list.component';
import { CustomerViewComponent } from './components/customer-view/customer-view.component';

export const routes: Routes = [
	{
		path: 'list',
		component: CustomerListComponent
	},
	{
		path: 'view',
		component: CustomerViewComponent
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class CustomersRoutingModule {}
