import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/apiservice/api.service';

@Component({
	selector: 'dl-spla-order-default',
	templateUrl: './order-form.default.component.html',
	styleUrls: ['./order-form.default.component.scss']
})
export class OrderFormDefaultComponent implements OnInit, AfterViewInit {
	public displayedColumns: string[] = ['name', 'count'];
	public license: LicenseInformation[];

	constructor(private apiService: ApiService) {}

	async ngOnInit(): Promise<void> {
		const result = await this.apiService.get<{ format: string }, LicenseInformation[]>(
			'spla/ad/exportOrderDefault',
			{
				format: 'application/json'
			}
		);

		this.license = result.data;
	}
	ngAfterViewInit(): void {}

	isNumber(val: any): boolean {
		return typeof val === 'number';
	}
}

export interface LicenseInformation {
	name: string;
	count: number;
}
