import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { CountUpModule } from 'ngx-countup';
import { CustomersModule } from '../customers/customers.module';
import { SplaConfigComponent } from './config/config/config.component';
import { SplaConfigCustomerComponent } from './config/customer/customer.component';
import { SplaConfigCustomerUserViewComponent } from './config/customers/customer-user-view/customer-user-view.component';
import { SplaConfigAddReportComponent } from './config/customers/customer-view/add-report/add-report.component';
import { SplaConfigCustomerViewComponent } from './config/customers/customer-view/customer-view.component';
import { SplaConfigViewReportComponent } from './config/customers/customer-view/view-report/view-report.component';
import { SplaConfigCustomersComponent } from './config/customers/customers.component';
import { SplaIndividualUserViewComponent } from './config/customers/individual-user-view/individual-user-view.component';
import { SplaConfigOptimizerComponent } from './config/optimizer/optimizer.component';
import { SplaConfigAddProductComponent } from './config/products/add-product-to-customer/add-product.component';
import { SplaConfigProductComponent } from './config/products/product.component';
import { SplaDashboardDateComponent } from './dashboard-date/dashboard-date.component';
import { SplaDashboardCustomerComponent } from './dashboard/customer/customer.component';
import { SplaDashboardComponent } from './dashboard/dashboard/dashboard.component';
import { SplaDashboardLicenseComponent } from './dashboard/license/license.component';
import { SplaDashboardVmDetailsComponent } from './dashboard/vmdetails/vmdetails.component';
import { OrderFormAcademicComponent } from './order-form/academic/order-form.academic.component';
import { OrderFormDefaultComponent } from './order-form/default/order-form.default.component';
import { SplaRoutingModule } from './spla-routing.module';

@NgModule({
	declarations: [
		SplaConfigComponent,
		SplaConfigCustomerComponent,
		SplaConfigOptimizerComponent,
		SplaDashboardComponent,
		SplaDashboardCustomerComponent,
		SplaDashboardDateComponent,
		SplaDashboardLicenseComponent,
		SplaDashboardVmDetailsComponent,
		OrderFormDefaultComponent,
		OrderFormAcademicComponent,
		SplaConfigProductComponent,
		SplaConfigAddProductComponent,
		SplaConfigCustomersComponent,
		SplaConfigCustomerViewComponent,
		SplaConfigCustomerUserViewComponent,
		SplaIndividualUserViewComponent,
		SplaConfigAddReportComponent,
		SplaConfigViewReportComponent
	],
	exports: [
		SplaConfigComponent,
		SplaConfigCustomerComponent,
		SplaConfigOptimizerComponent,
		SplaDashboardComponent,
		SplaDashboardCustomerComponent,
		SplaDashboardLicenseComponent,
		SplaDashboardVmDetailsComponent,
		OrderFormDefaultComponent,
		SplaConfigProductComponent,
		SplaConfigAddProductComponent,
		SplaConfigCustomersComponent,
		SplaConfigCustomerViewComponent
	],
	imports: [
		CommonModule,
		CountUpModule,
		FlexLayoutModule,
		FormsModule,
		MatButtonModule,
		MatCardModule,
		MatDividerModule,
		MatFormFieldModule,
		MatIconModule,
		MatInputModule,
		MatCheckboxModule,
		MatListModule,
		MatRadioModule,
		MatSelectModule,
		MatTableModule,
		MatTreeModule,
		MatTreeModule,
		MatTooltipModule,
		MatStepperModule,
		ReactiveFormsModule,
		SplaRoutingModule,
		CustomersModule
	]
})
export class SplaModule {}
