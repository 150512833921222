import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from 'src/app/services/apiservice/api.service';
import { Customer, CustomerService } from '../../customers/services/customer.service';

@Injectable({
	providedIn: 'root'
})
export class ReportService {
	private isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	private reports: { [id: string]: Report } = undefined;
	constructor(private apiService: ApiService, private customerService: CustomerService) {}

	loading(): Observable<boolean> {
		return this.isLoading.asObservable();
	}

	async getAllCustomerReports(): Promise<Report[]> {
		// if already, immediately return
		if (!!this.reports) {
			return Object.values(this.reports);
		}

		this.isLoading.next(true);

		const response = (await this.apiService.get<{}, Report[]>('reports/customer/reports')).data;

		this.reports = {};
		response.forEach((report) => (this.reports[report.Name] = report));

		this.isLoading.next(false);
		return response;
	}

	async getCustomerReports(customer: string): Promise<Report[]> {
		this.isLoading.next(true);

		const response = (await this.apiService.get<{}, Report[]>('reports/customer/list', { Customer: customer }))
			.data;

		this.isLoading.next(false);

		return response;
	}

	async attachNoConfigCustomerReport(customer: string, report: NoConfigurationReport): Promise<boolean> {
		return !(await this.apiService.post(`reports/customer/${report.Endpoint}/attach`, { Customer: customer })).data;
	}

	async listCustomersForReport(report: string): Promise<Customer[]> {
		await this.customerService.getAllCustomers();
		return Promise.all(
			(await this.apiService.get<{}, CustomerResponse[]>(`reports/customer/${report}/list`)).data.map(
				async (customer) => await this.customerService.getCustomerById(customer.Customer)
			)
		);
	}
}

interface CustomerResponse {
	Customer: string;
}

export type Report = StandardReport | NoConfigurationReport;

export interface StandardReport {
	Name: string;
	Configuration: true;
}

export function isNoConfigurationReport(report: Report): report is NoConfigurationReport {
	return !report.Configuration;
}

export interface NoConfigurationReport {
	Name: string;
	Endpoint: string;
	Configuration: false;
}
