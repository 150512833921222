<mat-card-header>
	<mat-card-title>
		<h3 style="display: flex; justify-content: flex-start;">
			<mat-icon style="margin-right: 5px">vpn_key</mat-icon>
			<span style="margin-left: 10px">Change Password</span>
		</h3>
	</mat-card-title>
</mat-card-header>
<mat-card-content>
	<div style="margin: 10px 60px">
		<form [formGroup]="newPasswordForm" #form="ngForm" fxFlex="grow" fxLayout="column" fxLayoutGap="15px"
			fxLayoutAlign="center stretch" (ngSubmit)="changePassword()">
			<div fxFlex="0 0 auto" fxLayout="column" fxLayoutGap="15px">
				<mat-form-field style="width: 100%">
					<mat-icon matPrefix style="margin-right: 5px; margin-top: -5px">lock</mat-icon>
					<input matInput type="password" placeholder="Current password" required
						formControlName="oldpassword">
					<mat-error *ngIf="newPasswordForm.controls['oldpassword'].hasError('minlength')">
						Passwort must be at least 8 characters
					</mat-error>
					<mat-error
						*ngIf="newPasswordForm.controls['password1'].hasError('pattern') && !newPasswordForm.controls['password1'].hasError('minlength')">
						Passwords requires: lower case, upper case, digit, special character.
					</mat-error>
				</mat-form-field>

				<mat-form-field style="width: 100%">
					<mat-icon matPrefix style="margin-right: 5px; margin-top: -5px">lock</mat-icon>
					<input matInput type="password" placeholder="New Password" required formControlName="password1">
					<mat-error *ngIf="newPasswordForm.controls['password1'].hasError('minlength')">
						Passwort must be at least 8 characters
					</mat-error>
					<mat-error
						*ngIf="newPasswordForm.controls['password1'].hasError('pattern') && !newPasswordForm.controls['password1'].hasError('minlength')">
						Passwords requires: lower case, upper case, digit, special character.
					</mat-error>
				</mat-form-field>

				<mat-form-field style="width: 100%">
					<mat-icon matPrefix style="margin-right: 5px; margin-top: -5px">lock</mat-icon>
					<input matInput type="password" placeholder="Confirm Password" [errorStateMatcher]="verifyPassword"
						required formControlName="password2">
					<mat-error *ngIf="newPasswordForm.errors?.passwordmatch">
						Password does not match.
					</mat-error>
				</mat-form-field>
				<button type="submit" style="display:none" [disabled]="newPasswordForm.invalid || changing">hidden
					submit</button>
			</div>
		</form>
	</div>
</mat-card-content>
<mat-card-actions>

	<div style="float: right; margin-right: 20px" matTooltip="Please enter valid data in the login form above"
		[matTooltipDisabled]="newPasswordForm.valid">
		<button mat-raised-button color="primary" style="margin-right: 20px" (click)="cancel()">Cancel</button>
		<button mat-raised-button color="primary" [disabled]="newPasswordForm.invalid || changing"
			(click)="changePassword()">
			<div fxLayout="row" fxFlexAlign="center">
				<div style="padding: 5.5px 11px 5.5px 0" *ngIf="changing">
					<mat-progress-spinner color="primary" mode="indeterminate" diameter="25"></mat-progress-spinner>
				</div>
				<span>Change Password</span>
			</div>
		</button>
	</div>
</mat-card-actions>