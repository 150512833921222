import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import Auth from '@aws-amplify/auth';
import { AuthClass } from '@aws-amplify/auth/lib-esm/Auth';
import { Observable } from 'rxjs';
import { AuthGuard } from '../auth/auth.guard';
import { ChangePasswordComponent } from '../auth/change/changepassword.component';
import { LoginComponent } from '../auth/login/login.component';

@Component({
	selector: 'dl-layout',
	templateUrl: './layout.component.html',
	styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements AfterViewInit {
	title = 'ks-db-frontend';

	public signedIn: Observable<boolean>;

	public userName: Observable<string>;

	public sidenavOpen = true;

	@ViewChild('content', { read: ElementRef, static: true }) ref: ElementRef;

	constructor(private authGuard: AuthGuard, private dialog: MatDialog) {
		this.signedIn = authGuard.LoggedIn;
		this.userName = authGuard.UserName;
	}

	public async logout(): Promise<void> {
		const auth: AuthClass = Auth;
		await auth.signOut();
	}

	public login(): void {
		this.dialog.open(LoginComponent, {
			width: '500px'
		});
	}

	public changePassword(): void {
		this.dialog.open(ChangePasswordComponent, {
			width: '500px'
		});
	}

	public toggleNav(): void {
		this.sidenavOpen = !this.sidenavOpen;
	}

	ngAfterViewInit(): void {
	}
}
