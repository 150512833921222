import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatTreeModule } from '@angular/material/tree';
import { RouterModule } from '@angular/router';
import { NavigationPanelComponent } from './navigation-panel/navigation-panel.component';

@NgModule({
    declarations: [NavigationPanelComponent],
    exports: [
        NavigationPanelComponent
    ],
    imports: [
        CommonModule,
        FlexLayoutModule,
        MatListModule,
        MatIconModule,
        MatTreeModule,
        MatButtonModule,
        RouterModule,
        MatRippleModule
    ]
})
export class NavigationModule { }
