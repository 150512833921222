import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'dl-authorization',
	templateUrl: './authorization.component.html',
	styleUrls: ['./authorization.component.scss']
})
export class AuthorizationComponent implements OnInit {
	constructor() {}

	ngOnInit(): void {}
}
