import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { ApiService } from 'src/app/services/apiservice/api.service';

export interface AddReportDialogData {
	customer: string;
	reports: string[];
}

export interface AddReportDialogResult {
	success: boolean;
}

@Component({
	selector: 'dl-add-report-dialog',
	templateUrl: './add-report.dialog.html',
	styleUrls: ['./add-report.dialog.scss']
})
export class AddReportDialogComponent implements OnInit {
	public reports: Report[] = [];

	public isInitializing = true;
	public initializingText = 'Initializing...';
	public selectedReport: Report = undefined;

	constructor(
		public apiService: ApiService,
		public dialogRef: MatDialogRef<AddReportDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: AddReportDialogData
	) {}

	async ngOnInit(): Promise<void> {
		this.reports = (await this.apiService.get<{}, Report[]>('ci/customer/reports/list')).data.filter(
			(report) => !this.data.reports.includes(report.Name)
		);
		this.isInitializing = false;
	}

	changeReport(event: MatSelectChange) {
		this.selectedReport = event.value;
	}

	onNoClick(): void {
		this.dialogRef.close({
			success: false
		});
	}

	async onOkClick(): Promise<void> {
		if (!this.selectedReport.RequiresConfig) {
			this.isInitializing = true;
			this.initializingText = 'Applying change';
			console.log(
				(
					await this.apiService.post(`ci/${this.selectedReport.AttachmentEndpoint}`, {
						Customer: this.data.customer
					})
				).data
			);
		}
		this.dialogRef.close({ success: true });
	}
}

interface Report {
	Name: string;
	RequiresConfig: boolean;
	AttachmentEndpoint: string;
}
