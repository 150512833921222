<div class="spla-customer-container">
    <div class="spacer">
        <mat-card class="mat-elevation-z8">
            <mat-card-title>
                <span>
                    <a [routerLink]="['../../../']" style="text-decoration: none; color: rgba(0,0,0,.87)">Dashboard</a>
                    <span> > </span>
                    <a [routerLink]="['../']" style="text-decoration: none; color: rgba(0,0,0,.87)">{{ customer }}</a>
                    <span> > </span>
                    <span>{{ readableLicense }}</span>
                </span>
                <div style="float: right" fxLayoutGap="16px">
                    <mat-form-field>
                        <mat-label>Suche</mat-label>
                        <input matInput type="text" [formControl]="search" placeholder="Suche">
                        <button mat-button *ngIf="searchValue" matSuffix mat-icon-button aria-label="Löschen"
                            (click)="searchValue=''">
                            <mat-icon>close</mat-icon>
                        </button>
                    </mat-form-field>
                    <button mat-stroked-button color="primary">Export</button>
                </div>
            </mat-card-title>
            <mat-card-content style="padding-top: 10px">
                <table mat-table [dataSource]="user" style="width: 100%">
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef>Name</th>
                        <td mat-cell *matCellDef="let element">{{ element.name }}</td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </table>
            </mat-card-content>
        </mat-card>
    </div>
</div>
