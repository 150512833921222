import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import Auth from '@aws-amplify/auth';
import Amplify from '@aws-amplify/core';
import * as process from 'process';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

process.env.ENDPOINT = 'CloudEndpoint';

const rememberMe = localStorage.getItem('rememberMe');

const test = {
	// identityPoolId: 'us-east-1:cb8ae39d-b731-4148-a952-11108091852c',

	region: 'eu-central-1',

	// Add the following to enable identity federation
	identityPoolId: environment.identityPoolId,
	identityPoolRegion: 'eu-central-1',

	// OPTIONAL - Amazon Cognito User Pool ID
	userPoolId: environment.userPoolId,

	// OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
	userPoolWebClientId: environment.userPoolWebClientId,

	// OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
	mandatorySignIn: true, // Change this to true when enable identity federation

	// OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
	authenticationFlowType: 'USER_SRP_AUTH',

	storage: rememberMe === 'true' ? localStorage : sessionStorage
};
console.log(test);

Auth.configure(test);

Amplify.configure({
	API: {
		endpoints: [
			{
				name: 'CloudEndpoint',
				endpoint: environment.production
					? 'https://api.explorer.dlake.cc-mase-automation.de/'
					: 'https://api.dev.explorer.dlake.cc-mase-automation.de/',
				region: 'eu-central-1'

				// remove when switching to IAM based authorization
				// custom_header: async () => {
				//     try {
				//         return { Authorization: (await Auth.currentAuthenticatedUser()).signInUserSession.idToken.jwtToken };
				//     } catch (e) {

				//     }
				//     return {};
				// }
			}
		]
	}
});

if (environment.production) {
	enableProdMode();
}

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.catch((err) => console.error(err));
