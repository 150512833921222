import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { CountUpModule } from 'ngx-countup';
import { AddReportDialogComponent } from './components/dialogs/add-report/add-report.dialog';
import { ReportDateSelectorComponent } from './components/report-date-selector/report-date-selector.component';
import { ReportsRoutingModule } from './reports.routing.module';
import { DateTimeService } from './services/datetime.service';
import { ReportService } from './services/report.service';

@NgModule({
	declarations: [AddReportDialogComponent, ReportDateSelectorComponent],
	exports: [AddReportDialogComponent, ReportDateSelectorComponent],
	imports: [
		CommonModule,
		CountUpModule,
		FlexLayoutModule,
		FormsModule,
		MatButtonModule,
		MatCardModule,
		MatDividerModule,
		MatProgressSpinnerModule,
		MatFormFieldModule,
		MatIconModule,
		MatDatepickerModule,
		MatInputModule,
		MatCheckboxModule,
		MatListModule,
		MatRadioModule,
		MatSelectModule,
		MatTableModule,
		MatTreeModule,
		MatTreeModule,
		MatTooltipModule,
		MatStepperModule,
		ReactiveFormsModule,
		ReportsRoutingModule
	]
})
export class ReportsModule {
	static forRoot(): ModuleWithProviders<ReportsModule> {
		return {
			ngModule: ReportsModule,
			providers: [{ provide: ReportService }, { provide: DateTimeService }]
		};
	}
}
