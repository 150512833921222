<div class="spla-customer-container">
    <div class="spacer">
        <mat-card>
            <mat-card-title>Optimizer > {{ type }} > {{ server }} </mat-card-title>
            <mat-card-content style="margin-top: 20px;">
                <form [formGroup]="configurationOptions">
                    <div fxLayout="row">
                        <p class="limit-grow" style="font-size: large; text-align: right; padding-right: 40px; padding-top: 15px; min-width: 0" fxFlex="1 0 20">Optimizer Function</p>
                        <mat-form-field fxFlex="1 0 80">
                            <mat-select formControlName="optimizer">
                                <mat-option value="graphflow">Graphflow</mat-option>
                                <mat-option value="dynamic">Dynamic</mat-option>
                                <mat-option value="ilp">ILP</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row">
                        <p class="limit-grow" style="font-size: large; text-align: right; padding-right: 40px; padding-top: 15px; min-width: 0" fxFlex="1 0 20">Cost Function</p>
                        <mat-form-field class="limit-grow" fxFlex="1 0 80">
                            <mat-select formControlName="cost">
                                <mat-option value="price">Price</mat-option>
                                <mat-option value="threshold">Threshold</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row">
                        <p class="limit-grow" style="font-size: large; text-align: right; padding-right: 40px; padding-top: 15px; min-width: 0" fxFlex="1 0 20">Standard License Price</p>
                        <mat-form-field class="limit-grow" fxFlex="1 0 80">
                            <input matInput value="300$">
                        </mat-form-field>
                    </div>

                    <div fxLayout="row">
                        <p class="limit-grow" style="font-size: large; text-align: right; padding-right: 40px; padding-top: 15px; min-width: 0" fxFlex="1 0 20">Datacenter License Price</p>
                        <mat-form-field class="limit-grow" fxFlex="1 0 80">
                            <input matInput value="800$">
                        </mat-form-field>
                    </div>

                    <div fxLayout="row">
                        <p class="limit-grow" style="font-size: large; text-align: right; padding-right: 40px; padding-top: 15px; min-width: 0" fxFlex="1 0 20">OS Filter</p>
                        <mat-form-field class="limit-grow" fxFlex="1 0 80">
                            <input matInput value=".*Windows.*">
                        </mat-form-field>
                    </div>
                </form>
                
            </mat-card-content>
        </mat-card>
    </div>
</div>
