import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { QueryService } from 'src/app/services/queryservice/query.service';
import { TableColumn } from 'src/app/visualization/table-viz/table-viz.component';

export interface VmElement {
    name: string;
    powerstate: string;
    customer: string;
    operatingsystem: string;
}

const VMS_TABLE: TableColumn<VmElement>[] = [
    {
        header: 'Name',
        property: 'name'
    },
    {
        header: 'Customer',
        property: 'customer'
    },
    {
        header: 'OS',
        property: 'operatingsystem'
    },
    {
        header: 'Powerstate',
        property: 'powerstate'
    },
];


@Component({
    selector: 'dl-infrastructure-vmware',
    templateUrl: './vmware.component.html',
    styleUrls: ['./vmware.component.scss'],
})
export class VmwareComponent implements OnInit, AfterViewInit {
    columns = VMS_TABLE;

    constructor(private queryService: QueryService, private changeDetector: ChangeDetectorRef) {

    }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
    }

    ngAfterViewChecked() { this.changeDetector.detectChanges(); }
}
