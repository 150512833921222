<h1 mat-dialog-title>Add a report</h1>
<div mat-dialog-content fxLayout="column">
	<div *ngIf="isInitializing" class="center-vertical">
		<mat-spinner></mat-spinner>
		<h3 style="font-weight: bold; margin-top: 10px; text-align: center;">{{ initializingText }}</h3>
	</div>
	<div *ngIf="!isInitializing && reports.length === 0" style="font-weight: bold; color: darkred;">
		Customer has already subscribed to all available reports
	</div>
	<mat-form-field *ngIf="!isInitializing">

		<mat-label>Report</mat-label>
		<mat-select (selectionChange)="changeReport($event)">
			<mat-option *ngFor="let report of reports" [value]="report">{{ report.Name }}</mat-option>
		</mat-select>
	</mat-form-field>
</div>
<div mat-dialog-actions class="float-right">
	<button mat-button (click)="onNoClick()">Cancel</button>
	<button mat-raised-button color="accent" (click)="onOkClick()" [disabled]="!selectedReport">
		Ok
	</button>
</div>