import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { QueryService } from 'src/app/services/queryservice/query.service';

export interface DisplayColumn {
    prop: string;
    name: string;
}

export interface VmElement {
    name: string;
    powerstate: string;
    customer: string;
    operatingsystem: string;
}

export interface TableColumn<T> {
    header: string;
    property: keyof T;
    type?: string;
}


@Component({
    selector: 'dl-table-viz',
    templateUrl: './table-viz.component.html',
    styleUrls: ['./table-viz.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition((from, to) => { console.log(from, to); return true; }, animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ])
    ],
})
export class TableVizComponent<T> implements OnInit, AfterViewInit {
    displayedColumns: string[] = [];
    dataSource = new MatTableDataSource([]);


    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @Input() columns: TableColumn<T>[];
    @Input() resourceName: string;


    expandedElement: T | null;
    resultsLength = 0;

    constructor(private queryService: QueryService) {

    }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
        this.displayedColumns = this.columns.map(column => column.property as string);
        this.dataSource.paginator = this.paginator;

        setTimeout(async () => {
            this.dataSource.data = await this.queryService.getData(this.resourceName, undefined, 0);
            this.resultsLength = this.dataSource.data.length * 2;
        }, 200);

        this.paginator.page.subscribe(async (event) => {
            const page = this.paginator.pageIndex;
            if (this.dataSource.data.length <= this.paginator.pageSize * (page + 1)) {
                const newData = await this.queryService.getData(this.resourceName, undefined, page);
                this.dataSource.data = [...this.dataSource.data, ...newData];
                this.resultsLength = this.dataSource.data.length + this.paginator.pageSize;
            }
        });
    }
}
