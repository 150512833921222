<div fxLayout="column" class="navigation-panel-container">
	<ng-container [ngSwitch]="loggedIn">
		<div *ngSwitchCase="true">
			<mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree">
				<!-- This is the tree node template for leaf nodes -->
				<mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
					<li class="mat-tree-node visualization-provider" matRipple [routerLink]="node.path">
						<!-- use a disabled button to provide padding for tree leaf -->
						<button mat-icon-button disabled></button>
						{{node.name}}
					</li>
				</mat-tree-node>
				<!-- This is the tree node template for expandable nodes -->
				<mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
					<li>
						<div class="mat-tree-node">
							<button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.name">
								<mat-icon class="mat-icon-rtl-mirror">
									{{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
								</mat-icon>
							</button>
							{{node.name}}
						</div>
						<ul [class.example-tree-invisible]="!treeControl.isExpanded(node)">
							<ng-container matTreeNodeOutlet></ng-container>
						</ul>
					</li>
				</mat-nested-tree-node>
			</mat-tree>

			<!--<mat-nav-list>
        <a mat-list-item routerLink="/start" class="link">
          <mat-icon svgIcon="dashboard" mat-list-icon></mat-icon>
          <span style="margin-left: 10px"> Dashboard </span>
        </a>
      </mat-nav-list>-->
		</div>
		<p class="mat-body-strong" *ngSwitchCase="false">Please login to use the navigation panel!</p>
	</ng-container>
</div>
