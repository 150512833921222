export const productNames: { [key: string]: string } = {
	251: '3S_Solyp',
	187: '3S_vSnagIT',
	154: '3S_Notepadpp',
	7: 'MS-Navision',
	115: '3S_Citrix_Client',
	286: 'MS-VisualStudioPro',
	117: '3S_CTI',
	47: 'LotusNotes',
	184: '3S_vPklink',
	156: '3S_Perkura_VIS',
	122: '3S_Fahrkartenverwaltung',
	273: '3M_Zeugnisgenerator',
	145: '3S_MTS.COM',
	195: '3M_DresdnerBankWebbanking',
	256: '3S_PC-Terminal',
	130: '3S_INFOR',
	79: '3S_AccessAS400',
	172: '3S_vCom4Tel',
	69: '3S_MS-VisioViewer',
	110: '3S_Baan',
	8: '3M_IrfanView',
	288: '3M_Skype',
	229: '3L_PDF-Editor-4',
	62: 'StartMenu',
	226: '3L_SAPBusinessObjects',
	197: '3M_Elster',
	244: '3S_SEPAConverter',
	230: '3L_Bestmix',
	54: 'MS-LanguagePreferences',
	287: '3M_ESTOS_ProCall',
	241: '3S_CatMan',
	75: '3L_AdobeDistiller',
	254: '3L_MS-PowerPivot2010',
	163: '3S_RDP',
	89: '3S_PaisyClient',
	32: '3L_BOE_WebIntelligenceRichClient',
	131: '3S_IR-OX',
	238: '3M_MoveIT',
	185: '3S_vPort-Management',
	264: '3L_CaseWare_AuditAgent',
	220: '3M_VOKS',
	200: '3M_EWB',
	278: 'RDS (HLSM-ADMOP-P01)',
	113: '3M_Broadgun',
	118: '3S_D3',
	198: '3M_EpKid',
	153: '3M_DiamantTaxonomie',
	135: '3S_Magick',
	164: '3S_Romax',
	56: 'MS-PictureManager',
	152: '3S_Nicim',
	283: '3L_Inubit-BPM',
	162: '3S_Putty',
	266: 'VDI',
	104: '3L_SAPLogon',
	139: '3S_MS-Financials',
	224: 'MS Exchange Resource',
	44: 'Infocom',
	39: '',
	10: '3S_vFootPASS',
	211: '3M_SAP-MDM-DataManager',
	82: '3L_Hunter',
	205: '3M_OctupusCTI',
	270: '3L_XPhoneUC',
	31: '3L_BOE_UniverseDesigner',
	160: '3S_Prof_Planner_Excel_Addin',
	206: '3M_ORGManager',
	191: '3S_vVisualSourceSafe',
	58: 'MS-SharePoint',
	3: 'RDP',
	232: '3L_PDFSplitter',
	65: 'AutoDeskDesignReview',
	253: '3S_GPG4Win',
	132: '3S_IR-WebOX',
	166: '3S_SnagIT',
	233: '3L_7PDF2Word',
	276: '3S_BK01',
	38: '3L_Gevis',
	169: '3S_vACT6',
	215: '3M_StarMoney',
	96: '3L_ProfessionalPlaner',
	210: '3M_SAP-MDM-Console',
	221: 'MS Exchange',
	181: '3S_vjZIP_EN',
	178: '3S_vGS-Stat',
	123: '3S_FileSearch',
	151: '3M_CoporatePlaner',
	274: '3M_Ticketmanager',
	268: '3L_MediFox',
	228: '3S_SW_CiscoWebEx',
	81: '3L_GoToMeeting',
	2: 'MS-OfficeStd',
	194: '3M_Dienstplan',
	120: '3S_eFA',
	21: 'MS-Publisher',
	231: 'Shared CAG',
	183: '3S_vOnNet',
	40: 'Synchronize It',
	265: '3M_HabelOutlookAddin',
	14: 'MS-Excel',
	289: 'Enterprise Cloud Drive',
	208: '3M_PBSMain',
	203: '3M_InterrollmySAP',
	55: 'MS-Onenote',
	18: 'MS-Outlook',
	263: '3L_IX-Haus',
	219: '3M_OracleSQLDeveloper',
	239: '3M_OpenOffice',
	100: '3L_RWDuPerform',
	155: '3S_PartSmart',
	138: '3S_MS-Fibu',
	170: '3S_vAIB',
	108: '3S_Autodesk-Design-Reviewer',
	246: '3S_EBilanz',
	128: '3S_Hoppenstedt',
	202: '3M_Haufe',
	98: '3L_Queen',
	85: '3L_Lexware',
	95: '3L_AdobePhotoshopElements',
	124: '3S_FotoStation-Test',
	165: '3S_SAP-MDM_Language-Selector',
	142: '3M_AbasERP',
	25: 'MS-Word',
	285: 'Skype_For_Business',
	201: '3M_FibuNet',
	12: 'MS-Access',
	9: '3L_MS-VisualStudio2012ExpressionWeb',
	173: '3S_vEpKid',
	53: 'MS-Frontpage',
	97: '3L_QDA',
	199: '3M_ElsterProCall',
	209: '3M_ProdaticEDV',
	52: 'MS-ExpressionWeb',
	225: '3L_AIDA_Zeiterfassung',
	4: 'zz_3M_SFirm32',
	111: '3L_Wodis',
	161: '3S_Push',
	13: 'MS-Communicator',
	280: 'MS-ProjectPro',
	267: '3L_Datawarehouse',
	277: 'RDS (HLSM-RAFM01)',
	190: '3S_vViFlow',
	127: '3S_GuiXT',
	119: '3S_ecoINVOICE',
	16: 'MS-OfficeTools',
	240: '3L_EnergyTrader',
	248: '3S_Sdok',
	271: '3L_XPhoneUC_TeamPanel',
	86: '3L_LexwareKassenbuch',
	252: '3S_7Zip',
	80: '3L_GoogleChrome',
	149: '3M_Caseware',
	245: '3M_EML_Transmitter',
	72: 'WebbankingMedium',
	193: '3S_XLCubed',
	76: '3L_AdobeIndesign',
	176: '3S_vFinancials-Test',
	143: '3S_MS-VisioViewer',
	150: '3S_NETSDK',
	93: '3L_Netviewer',
	45: 'InforFina',
	41: 'AdobeAcrobatWriter',
	242: '3S_TrueCrypt',
	217: '3M_Topsoz',
	148: '3M_BEOExpoWin',
	17: 'MS-OneNote',
	136: '3S_MIB',
	247: '3S_Perfidia',
	133: '3S_Ix-Haus',
	6: 'Windows CAL',
	157: '3S_PerkuraOutputManager',
	66: 'Excelviewer',
	63: '3M_TeamViewer',
	46: 'InforSW',
	50: 'MS-ClipOrganizer',
	189: '3S_vUconeer',
	227: '3S_SAP_ERP_client_for_E-Bilanz',
	36: '3L_DWGTrueView',
	59: 'MS-UploadCenter',
	168: '3S_TS-Benchmark',
	171: '3S_vBG-Bau',
	137: '3S_MSETaifun',
	71: 'WebanwendungMedium',
	20: 'MS-ProjectStd',
	19: 'MS-PowerPoint',
	207: '3M_OutlookExpress',
	213: '3M_Sfirm32',
	223: '3S_ListLabelViewer',
	141: '3S_MS-Powerpoint-Viewer',
	204: '3M_IfranView',
	214: '3M_SnippingTool',
	73: '3L_AdobePhotoshop',
	262: '3S_PersyOutlook',
	250: 'ESCRIBA',
	188: '3S_Tasktocalv',
	236: '3S_Linkpoint360',
	102: '3L_SAPBusinessExplorer',
	103: '3L_SAPGui',
	279: 'SSL VPN',
	106: '3L_SVNetClassic',
	177: '3S_vFoot-PASS',
	88: '3L_MapInfo',
	192: '3S_WinSCP',
	281: 'MS-VisioPro',
	218: '3M_CADViewer',
	125: '3S_Gaeb',
	78: '3L_Dakota',
	257: '3L_PDF-Editor4 NICHT BENUTZEN',
	275: '3L_Oracle',
	282: 'MS-Lync',
	175: '3S_VFD',
	61: 'PrivateCrypto',
	158: '3M_PIMphony',
	174: '3S_vEWB',
	134: '3S_Klicktel',
	33: '3L_CPS',
	222: 'Terminalservice',
	34: '3L_Datev',
	35: '3L_Diamant',
	114: '3S_Campionature',
	42: 'Emailarchivierung',
	167: '3S_SQL-Editor',
	1: 'MS-OfficePro',
	272: '3L_ProAlpha',
	77: '3L_Aida',
	49: 'MS-BCM',
	105: '3L_SmartFTP',
	37: '3L_Firefox',
	147: '3S_MultiCash',
	121: '3S_FactoryEvents',
	234: 'Cobion',
	196: '3M_ELOClient',
	92: '3S_Aris',
	101: '3L_SageHWP',
	51: 'MS-DigitalCertificate',
	109: '3L_XMind',
	216: '3M_TenadoCAD',
	112: '3S_BietPr',
	144: '3M_ACT2000',
	64: 'ASPCM',
	87: '3L_Loga',
	43: 'Hardcopy',
	146: '3M_AllforFinance',
	5: '3S_PDF',
	237: '3L_LogPro',
	22: 'MS-SharepointDesigner',
	284: 'ProAlpha Support User',
	107: '3L_DeskShare',
	68: '3S_MS-PowerpointViewer',
	258: '3L_WebICE',
	243: '3S_TimeCard',
	90: '3L_MindManager',
	29: '3L_AdobeAcrobat',
	24: 'MS-VisioStd',
	83: '3L_IBMTest',
	23: 'MS-SharepointWorkspace',
	30: '3L_AGVIP',
	15: 'MS-InfoPath',
	126: '3S_GSP',
	116: '3S_CoTel',
	28: '3L_ABAS-DMS',
	159: '3S_Polifoam',
	67: '3S_OfficeViewer',
	11: '3S_vPort Management',
	269: '3L_Bi1',
	91: '3L_MTSKonfigEditor',
	180: '3S_vjZIP',
	235: '3M_E-Post-Client',
	84: '3L_JetReports',
	179: '3S_VIP_Paisy',
	94: '3L_OracleCrystallBall',
	182: '3S_vNotepad2',
	140: '3S_MS-Office-Viewer',
	249: '3S_Verify',
	57: 'MS-Query',
	186: '3S_vSmartFTP',
	60: 'Passwordmanager',
	212: '3M_SAP-MDM-ImportManager',
	48: 'MS-Access2007',
	74: '3L_AdobeBridge',
	70: 'WordViewer',
	129: '3S_Infoplan-Delta-5',
	99: '3L_Rehadat'
};
