import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ApiResponse, ApiService } from 'src/app/services/apiservice/api.service';
import { LicenseService } from 'src/app/services/licenseservice/license.service';

@Component({
	selector: 'dl-spla-dashboard-license',
	templateUrl: './license.component.html',
	styleUrls: ['./license.component.scss'],
})
export class SplaDashboardLicenseComponent implements OnInit, AfterViewInit {
	public customer: string;
	public readableLicense: string;
	public license: string;
	public searchValue: string;
	public readonly search: FormControl = new FormControl('');

	public displayedColumns: string[] = ['name'];
	public user: { name: string }[];

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private apiService: ApiService,
		private licenseService: LicenseService
	) { }

	async ngOnInit(): Promise<void> {
		this.route.params.subscribe(params => {
			this.customer = params.customer;
			this.readableLicense = params.license;
		});
		this.license = this.licenseService.getLicenseName(this.readableLicense);

		this.user = await this.getProductUsers(this.customer, this.license).then(
			(response: ProductUsersResponse[]) => response.map((item) => ({ name: item.displayname }))
		);

		this.search.valueChanges
			.pipe(
				debounceTime(300),
				distinctUntilChanged()
			)
			.subscribe((value: string) => {
				// WIP: NOT FILTERING
				console.log(value);
				console.log(this.user.filter((user) => user.name === value));
			});
	}

	ngAfterViewInit(): void {
	}

	async getProductUsers(customer: string, license: string, date?: string): Promise<ProductUsersResponse[]> {
		return await this.apiService
			.get<{ customer: string, license: string, date?: string }, ProductUsersResponse[]>(
				'spla/ad/productUsers',
				date ? { customer, license, date } : { customer, license }
			)
			.then((response: ApiResponse<ProductUsersResponse[]>) => response.data);
	}

	async customerClicked(customer: string): Promise<void> {
		await this.router.navigate([customer], { relativeTo: this.route });
	}
}

export interface ProductUsersResponse {
	samaccountname?: string;
	objectsid?: string;
	useraccountcontrol?: string;
	displayname: string;
	distinguishedname?: string;
	mail?: string;
	department?: string;
}
