<h1 mat-dialog-title> <span style="line-height: 33px; vertical-align: center">{{ data.title }}</span></h1>
<div mat-dialog-content>
  <form>
    <mat-form-field style="width: 100%">
      <input matInput [placeholder]="data.description" #directoryName>
    </mat-form-field>
  </form>

</div>
<div mat-dialog-actions fxLayoutAlign="flex-end">
  <button mat-raised-button mat-dialog-close color="primary" position="right">Cancel</button>
  <button mat-raised-button [mat-dialog-close]="directoryName.value" color="primary" position="right">OK</button>
</div>
