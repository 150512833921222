<div fxFlexFill fxLayout="column">
	<div fxFlex="1 1 auto" style="overflow: auto">
		<table mat-table [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z8">

			  <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
			<<!--ng-container matColumnDef="expandedDetail">
				<td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
					<div class="vm-element-details"
						style="height: 0px; min-height: 0px;"
						[@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
						Hallo Welt
					</div>
				</td>
			</>-->

			<!--- Note that these columns can be defined in any order.
				  The actual rendered columns are set as a property on the row definition" -->
			<ng-container matColumnDef="{{ column.property }}" *ngFor="let column of columns">
				<th mat-header-cell *matHeaderCellDef> {{ column.header }} </th>
				<td mat-cell *matCellDef="let element"> {{element[column.property]}} </td>
			</ng-container>
		  
			<tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns;"
				class="vm-element-row"
				[class.vm-expanded-row]="expandedElement === row"
				(click)="expandedElement = expandedElement === row ? null : row"></tr>
			<!-- <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="vm-detail-row"></tr> -->
		  </table>
	</div>


	  <mat-paginator fxFlex="1 0 56px" [pageSize]="50" [length]="resultsLength">
	  </mat-paginator>
</div>
