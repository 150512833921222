import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RestAPI, RestAPIClass } from '@aws-amplify/api-rest';
import * as process from 'process';

export interface ApiResponse<O> {
	data: O;
}

@Injectable({
	providedIn: 'root'
})
export class ApiService {
	private api: RestAPIClass;

	constructor(private snackBar: MatSnackBar) {
		this.api = RestAPI;
	}

	public get API(): RestAPIClass {
		return this.api;
	}

	public async post<Input, Output>(path: string, data?: Input): Promise<ApiResponse<Output>> {
		try {
			return await this.API.post(process.env.ENDPOINT, path, { response: true, body: data ? data : {} });
		} catch (err) {
			if (err.response.status === 403) {
				this.snackBar.open('You are not authorized to perform this action', 'Ok', { duration: 10000 });
			} else {
				this.snackBar.open('Unhandled error code ' + err.response.status, 'Ok', { duration: 10000 });
			}
		}

		return { data: null };
	}

	public async delete<Input, Output>(path: string, data?: Input): Promise<ApiResponse<Output>> {
		try {
			return await this.API.del(process.env.ENDPOINT, path, { response: true, body: data ? data : {} });
		} catch (err) {
			if (err.response.status === 403) {
				this.snackBar.open('You are not authorized to perform this action', 'Ok', { duration: 10000 });
			} else {
				this.snackBar.open('Unhandled error code ' + err.response.status, 'Ok', { duration: 10000 });
			}
		}

		return { data: null };
	}

	public async get<Input, Output>(path: any, queryParameter?: any, type?: string): Promise<ApiResponse<Output>> {
		return await this.API.get(process.env.ENDPOINT, path, {
			response: true,
			queryStringParameters: queryParameter,
			responseType: type
		});
	}

	public async download<Input>(
		path: any,
		filename: string,
		queryParameter?: Input,
		additionalHeaders?: any
	): Promise<void> {
		const result = await this.API.get(process.env.ENDPOINT, path, {
			response: true,
			queryStringParameters: queryParameter,
			responseType: 'blob',
			headers: {
				Accept: 'application/binary',
				...additionalHeaders
			}
		});

		console.log(result);

		const downloadURL = window.URL.createObjectURL(result.data);
		const link = document.createElement('a');
		link.href = downloadURL;
		link.download = filename;
		link.click();
	}
}
