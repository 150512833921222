import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SplaConfigComponent } from './config/config/config.component';
import { SplaConfigCustomerComponent } from './config/customer/customer.component';
import { SplaConfigCustomerUserViewComponent } from './config/customers/customer-user-view/customer-user-view.component';
import { SplaConfigAddReportComponent } from './config/customers/customer-view/add-report/add-report.component';
import { SplaConfigCustomerViewComponent } from './config/customers/customer-view/customer-view.component';
import { SplaConfigViewReportComponent } from './config/customers/customer-view/view-report/view-report.component';
import { SplaConfigCustomersComponent } from './config/customers/customers.component';
import { SplaIndividualUserViewComponent } from './config/customers/individual-user-view/individual-user-view.component';
import { SplaConfigOptimizerComponent } from './config/optimizer/optimizer.component';
import { SplaConfigAddProductComponent } from './config/products/add-product-to-customer/add-product.component';
import { SplaConfigProductComponent } from './config/products/product.component';
import { SplaDashboardDateComponent } from './dashboard-date/dashboard-date.component';
import { SplaDashboardCustomerComponent } from './dashboard/customer/customer.component';
import { SplaDashboardComponent } from './dashboard/dashboard/dashboard.component';
import { SplaDashboardLicenseComponent } from './dashboard/license/license.component';
import { SplaDashboardVmDetailsComponent } from './dashboard/vmdetails/vmdetails.component';
import { OrderFormAcademicComponent } from './order-form/academic/order-form.academic.component';
import { OrderFormDefaultComponent } from './order-form/default/order-form.default.component';

export const routes: Routes = [
	{
		path: 'config/products',
		component: SplaConfigProductComponent
	},
	{
		path: 'config/products/:product',
		component: SplaConfigAddProductComponent
	},
	{
		path: 'config',
		component: SplaConfigComponent
	},
	{
		path: 'config/customers',
		component: SplaConfigCustomersComponent
	},
	{
		path: 'config/customers/:customer',
		component: SplaConfigCustomerViewComponent
	},
	{
		path: 'config/customers/:customer/addReport',
		component: SplaConfigAddReportComponent
	},
	{
		path: 'config/customers/:customer/users',
		component: SplaConfigCustomerUserViewComponent
	},
	{
		path: 'config/customers/:customer/report/:report',
		component: SplaConfigViewReportComponent
	},
	{
		path: 'config/customers/:customer/users/:user',
		component: SplaIndividualUserViewComponent
	},
	{
		path: 'config/customer/:customer',
		component: SplaConfigCustomerComponent
	},
	{
		path: 'config/optimizer/:type/:server',
		component: SplaConfigOptimizerComponent
	},
	{
		path: '',
		component: SplaDashboardDateComponent,
		children: [
			{
				path: 'dashboard',
				component: SplaDashboardComponent
			},
			{
				path: 'dashboard/customer/:customer',
				component: SplaDashboardCustomerComponent
			},
			{
				path: 'dashboard/vmdetails/:type',
				component: SplaDashboardVmDetailsComponent
			},
			{
				path: 'dashboard/customer/:customer/:license',
				component: SplaDashboardLicenseComponent
			},
			{
				path: 'order/default',
				component: OrderFormDefaultComponent
			},
			{
				path: 'order/academic',
				component: OrderFormAcademicComponent
			}
		]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class SplaRoutingModule {}
