import { Injectable } from '@angular/core';
import Auth from '@aws-amplify/auth';
import { AuthClass } from '@aws-amplify/auth/lib-esm/Auth';
import { CognitoUser } from 'amazon-cognito-identity-js';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private auth: any;
  private user: CognitoUser;

  constructor() {
    this.auth = Auth;
  }

  public set User(user: CognitoUser) {
    this.user = user;
  }

  public get User(): CognitoUser {
    return this.user;
  }

  public get Auth(): AuthClass {
    return this.auth;
  }
}
