import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/apiservice/api.service';

@Component({
	selector: 'dl-spla-config-customer-view',
	templateUrl: './customer-view.component.html',
	styleUrls: ['./customer-view.component.scss']
})
export class SplaConfigCustomerViewComponent implements OnInit, AfterViewInit {
	private customer: string;

	public reports: Report[] = [];

	constructor(private router: Router, private route: ActivatedRoute, private apiSerivce: ApiService) {}

	async ngOnInit(): Promise<void> {
		this.route.params.subscribe(async (params) => {
			this.customer = params.customer;

			this.reports = (
				await this.apiSerivce.get<any, Report[]>('spla/reports/customer', {
					context: 'CUSTOMER',
					customer: this.customer
				})
			).data;
		});
	}

	ngAfterViewInit(): void {}

	async customerClicked(id: string): Promise<void> {
		await this.router.navigate([id], { relativeTo: this.route });
	}

	async userViewClicked(): Promise<void> {
		await this.router.navigate(['users'], { relativeTo: this.route });
	}

	async viewReport(report: Report): Promise<void> {
		await this.router.navigate(['report', report.name], { relativeTo: this.route });
	}

	async addReport(): Promise<void> {
		await this.router.navigate(['addReport'], { relativeTo: this.route });
	}
}

interface Report {
	name: string;
}
