import { Pipe, PipeTransform } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CustomerService } from '../services/customer.service';

@Pipe({ name: 'customerLongname' })
export class CustomerLongnamePipe implements PipeTransform {
	constructor(private customerService: CustomerService) {}

	transform(value: string, ...args: any[]): Observable<string> {
		const subject = new BehaviorSubject<string>(`${value} - Resolving...`);
		this.customerService.getCustomerById(value).then((result) => subject.next(result.Longname));
		return subject.asObservable();
	}
}
