<mat-toolbar color="primary">
	<div style="width: 100%; display: flex; flex-direction: row; justify-content: space-between">
		<div style="display: flex; flex-direction: row">
			<button mat-icon-button (click)="toggleNav()">
				<mat-icon>menu</mat-icon>
			</button>
			<span style="line-height: 40px;">Data-Explorer</span>
		</div>
		<div style="float: right">
			<ng-container *ngIf="signedIn | async">
				<button mat-button [matMenuTriggerFor]="menu">
					{{ userName | async }}
					<mat-icon>keyboard_arrow_down</mat-icon>
				</button>
				<mat-menu #menu="matMenu" xPosition="before">
					<button mat-menu-item (click)="changePassword()">Change password</button>
					<button mat-menu-item (click)="logout()">Logout</button>
				</mat-menu>
			</ng-container>
			<ng-container *ngIf="!(signedIn | async)">
				<button (click)="login()" mat-stroked-button class="login-button">Login</button>
			</ng-container>
		</div>
	</div>
</mat-toolbar>
<mat-sidenav-container class="sidenav-container" #content>
	<mat-sidenav [opened]="sidenavOpen" mode="side" position="start" class="sidenav-toolbar">
		<dl-navigation-panel></dl-navigation-panel>
	</mat-sidenav>
	<mat-sidenav-content class="sidenav-content">
		<router-outlet></router-outlet>
	</mat-sidenav-content>
</mat-sidenav-container>