<div class="spla-config-container">
	<div fxFlex="column" class="spacer fit-content-box">
		<div fxFlexFill fxLayout="column" fxLayoutGap="10px" class="fit-content-box">
			<mat-card class="mat-elevation-z8 fit-content-box" fxLayout="column" fxFlex="0 1 auto">
				<mat-card-title fxFlex="0 0 auto">Customers</mat-card-title>
				<mat-card-content fxFlex="0 0 95%" style="overflow-y: scroll">
					<mat-spinner *ngIf="isLoading" class="center-vertical"></mat-spinner>
					<mat-action-list>
						<button mat-list-item *ngFor="let customer of customers" (click)="viewCustomer(customer)">
							{{ customer.Longname }}
						</button>
					</mat-action-list>
				</mat-card-content>
			</mat-card>
		</div>

	</div>
</div>