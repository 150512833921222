import { AfterViewInit, Component, OnInit } from '@angular/core';

@Component({
	selector: 'dl-infrastructure-hyperv',
	templateUrl: './hyperv.component.html',
	// styleUrls: ['./hyperv.component.scss'],
})
export class HypervComponent implements OnInit, AfterViewInit {


	constructor() {
	}

	ngOnInit(): void {
	}

	ngAfterViewInit(): void {
	}
}
