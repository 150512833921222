import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class LicenseService {
	private licenseMap: LicenseMap[] = [
		{ name: 'citrix_base', readable: 'Citrix Base' },
		{ name: 'citrix_kauf_base', readable: 'Citrix Kauf Base' },
		{ name: 'citrix_kauf_premium', readable: 'Citrix Kauf Premium' },
		{ name: 'citrix_premium', readable: 'Citrix Premium' },
		{ name: 'desktop', readable: 'Terminalserver' },
		{ name: 'skype', readable: 'Skype for Business' },
		{ name: 'exchange_enterprise', readable: 'Microsoft Exchange Enterprise' },
		{ name: 'exchange_enterprise_plus', readable: 'Microsoft Exchange Enterprise Plus' },
		{ name: 'exchange_standard', readable: 'Microsoft Exchange Standard' },
		{ name: 'exchange_standard_plus', readable: 'Microsoft Exchange Standard Plus' },
		{ name: 'ms_office', readable: 'Microsoft Office' },
		{ name: 'ms_office_pro', readable: 'Microsoft Office Pro' },
		{ name: 'ms_office_std', readable: 'Microsoft Office Std' },
		{ name: 'project', readable: 'Microsoft Project' },
		{ name: 'sharepoint_enterprise', readable: 'Microsoft Sharepoint Enterprise' },
		{ name: 'sharepoint_foundation', readable: 'Microsoft Sharepoint Foundation' },
		{ name: 'sharepoint_std', readable: 'Microsoft Sharepoint Std' },
		{ name: 'visio', readable: 'Microsoft Visio' },
		{ name: 'windows', readable: 'Microsoft Windows' }
	];

	constructor() {}

	public getLicenseMap(): LicenseMap[] {
		return this.licenseMap;
	}

	public getReadableLicenseName(licenseName: string): string {
		return this.licenseMap.filter((license) => license.name === licenseName)[0].readable;
	}

	public getLicenseName(readableName: string): string {
		return this.licenseMap.filter((license) => license.readable === readableName)[0].name;
	}
}

export interface LicenseMap {
	name: string;
	readable: string;
}
