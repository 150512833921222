<div class="spla-config-container">
	<div fxFlex="column" class="spacer fit-content-box">
		<div fxFlexFill fxLayout="column" fxLayoutGap="10px" class="fit-content-box">
			<mat-card class="mat-elevation-z8">
				<mat-card-title>{{ customer | customerLongname | async }}</mat-card-title>
			</mat-card>
			<mat-card class="mat-elevation-z8 fit-content-box" fxLayout="column" fxFlex="0 1 auto">
				<mat-card-title>
					Reports
				</mat-card-title>
				<mat-card-content fxFlex="0 0 95%">
					<mat-spinner *ngIf="isLoading" class="center-vertical"></mat-spinner>
					<mat-action-list *ngIf="!isLoading">
						<button mat-list-item *ngFor="let report of reports" (click)="openReport(report)">
							{{ report }}
						</button>
						<button mat-list-item (click)="addReport()">
							<ng-container *ngIf="reports.length === 0">
								No reports configured for the customer. Click
								here to add a new report.
							</ng-container>
							<ng-container *ngIf="reports.length > 0">
								Click here to add a new report.
							</ng-container>
						</button>
					</mat-action-list>
				</mat-card-content>
			</mat-card>
		</div>

	</div>
</div>