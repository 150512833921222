import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../services/authservice/auth.service';
import { WarningDialogComponent } from '../../dialogs/warning-dialog/warning-dialog.component';

@Component({
	selector: 'dl-request-new-password',
	templateUrl: './request.newpassword.component.html',
	styleUrls: ['./request.newpassword.component.scss']
})
export class RequestNewPasswordComponent implements OnInit {
	public changing: boolean = false;

	public forgotPasswordForm = new FormGroup({
		username: new FormControl('', [Validators.email])
	});

	constructor(
		private dialog: MatDialog,
		private router: Router,
		private route: ActivatedRoute,
		private authService: AuthService
	) {}

	async ngOnInit(): Promise<void> {}

	public backToLogin(): void {
		this.router.navigate(['login'], { relativeTo: this.route.parent });
	}

	public requestNewPassword(): void {
		this.authService.Auth.forgotPassword(this.forgotPasswordForm.get('username').value)
			.then(() => {
				this.router.navigate(['forgot'], { relativeTo: this.route.parent });
			})
			.catch((err) => {
				this.dialog.open(WarningDialogComponent, {
					width: '400px',
					data: {
						description: err.message
					}
				});
			});
	}
}
