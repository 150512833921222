import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/apiservice/api.service';
import { CustomerService } from 'src/app/views/customers/services/customer.service';

@Component({
	selector: 'dl-spla-config-customer',
	templateUrl: './customers.component.html',
	styleUrls: ['./customers.component.scss']
})
export class SplaConfigCustomersComponent implements OnInit, AfterViewInit {
	public customers: Customer[] = [];

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private apiService: ApiService,
		private customerService: CustomerService
	) {}

	async ngOnInit(): Promise<void> {
		this.customers = (await this.apiService.get<{}, Customer[]>('spla/customers')).data;
	}

	ngAfterViewInit(): void {}

	async customerClicked(id: string): Promise<void> {
		await this.router.navigate([id], { relativeTo: this.route });
	}
}

interface Customer {
	name: string;
	longname: string;
}
