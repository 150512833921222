import { MatTreeNestedDataSource } from '@angular/material/tree';
import { NestedTreeControl } from '@angular/cdk/tree';
import { ActivatedRoute, Router } from '@angular/router';
import { AfterViewInit, Component, OnInit, ViewChild, ElementRef } from '@angular/core';

import chart from 'tui-chart';


/**
 * Data with nested structure.
 * Each node has a name and an optional list of children.
 */
interface HostNode {
	name: string;
	licensed: boolean;
	vms?: HostNode[];
}

const TREE_DATA: HostNode[] = [
	{
		name: 'Host1',
		licensed: false,
		vms: [
			{
				name: 'Vm1',
				licensed: true
			},
			{
				name: 'Vm2',
				licensed: true
			},
			{
				name: 'Vm5',
				licensed: true
			}
		]
	}, {
		name: 'Host2',
		licensed: true,
		vms: [
			{
				name: 'Vm3',
				licensed: false
			},
			{
				name: 'Vm4',
				licensed: false
			},
			{
				name: 'Vm5',
				licensed: true
			}
		]
	}
];

@Component({
	selector: 'dl-spla-dashboard-vmdetails',
	templateUrl: './vmdetails.component.html',
	styleUrls: ['./vmdetails.component.scss'],
})
export class SplaDashboardVmDetailsComponent implements OnInit, AfterViewInit {
	public type: string;

	public treeControl = new NestedTreeControl<HostNode>(node => node.vms);
	public dataSource = new MatTreeNestedDataSource<HostNode>();

	@ViewChild('barchart') barchartRef: ElementRef;

	constructor(private router: Router, private route: ActivatedRoute) {
		this.dataSource.data = TREE_DATA;
	}

	ngOnInit(): void {
		this.route.params.subscribe(params => {
			this.type = params.type;
		});
	}

	ngAfterViewInit(): void {
		console.log(this.barchartRef.nativeElement);

		const data = {
			categories: ['Customer'],
			series: [
				{
					name: 'Pironet',
					data: 115
				},
				{
					name: 'heidelberger Leben',
					data: 35
				},
				{
					name: 'JobAG',
					data: 118
				},
				{
					name: 'MOT',
					data: 13
				},
				{
					name: 'PRA',
					data: 20
				},
				{
					name: 'Not classified',
					data: 620
				}
			]
		};

		const options = {
			chart: {
				width: 1000,
				height: 600,
				title: 'Share of VMs by customers (Unit: Count)'
			},
			chartExportMenu: {
				visible: true
			},
			series: {
				radiusRange: ['40%', '100%'],
				showLegend: true,
				labelAlign: 'outer'
			}
		};

		const theme = {
			series: {
				colors: [
					'#83b14e', '#458a3f', '#295ba0', '#2a4175', '#289399',
					'#289399', '#617178', '#8a9a9a', '#516f7d', '#dddddd'
				]
			}
		};

		chart.pieChart(this.barchartRef.nativeElement, data, options);
	}

	async licenseClick(license: string): Promise<void> {
		await this.router.navigate([license], { relativeTo: this.route});
	}

	hasChild = (_: number, node: HostNode) => !!node.vms && node.vms.length > 0;
}
