<div class="spla-customer-container">
	<div class="spacer">
		<div fxFlexFill fxLayout="column" fxLayoutGap="10px">
			<ng-container *ngIf="userInformation">
				<mat-card fxFlexFill fxFlex="0 1 auto" class="mat-elevation-z8">
					<mat-card-title>
						Nutzeransicht
					</mat-card-title>
					<mat-card-content>
						<mat-list>
							<mat-list-item>
								IsExcludedForReporting: {{ userInformation.IsExcludedForReporting }}
							</mat-list-item>
							<mat-list-item>
								IsActive: {{ userInformation.IsActive }}
							</mat-list-item>
						</mat-list>
					</mat-card-content>
				</mat-card>
				<mat-card class="mat-elevation-z8">
					<mat-card-title>
						Produkte
					</mat-card-title>
					<mat-card-content>

						<span *ngIf="userProducts.length === 0">Keine Produkte
							zugewiesen</span>
						<div *ngIf="userProducts.length >= 0">
							<table mat-table [dataSource]="userProducts" class="mat-elevation-z8 product-table">
								<!-- Position Column -->
								<ng-container matColumnDef="name">
									<th mat-header-cell *matHeaderCellDef> Productname </th>
									<td mat-cell *matCellDef="let element"> {{element.name}} </td>
								</ng-container>

								<!-- Position Column -->
								<ng-container matColumnDef="reason">
									<th mat-header-cell *matHeaderCellDef> Reason </th>
									<td mat-cell *matCellDef="let element"> Belongs to group {{element.reason}} </td>
								</ng-container>

								<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
								<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
							</table>
						</div>

					</mat-card-content>
				</mat-card>
			</ng-container>

		</div>
	</div>
</div>