<div class="spla-config-container">
    <div class="spacer" fxLayout="column" fxLayoutGap="10px">
        <mat-card class="mat-elevation-z8">
            <mat-card-title>Customers</mat-card-title>
            <mat-card-content>
                <mat-action-list>
                    <button mat-list-item (click)="customerClicked('HLE')">Heidelberger Leben</button>
                    <button mat-list-item (click)="customerClicked('JOB')">JobAG</button>
                    <button mat-list-item (click)="customerClicked('PNDH')">Pironet</button>
                </mat-action-list>
            </mat-card-content>
        </mat-card>
        <mat-card class="mat-elevation-z8">
            <mat-card-title>VMWARE</mat-card-title>
            <mat-card-content>
                <mat-action-list>
                    <button mat-list-item (click)="optimizerClicked('vmware', 'VCenter1')">VCenter 1</button>
                    <button mat-list-item (click)="optimizerClicked('vmware', 'VCenter2')">VCenter 2</button>
                    <button mat-list-item (click)="optimizerClicked('vmware', 'VCenter3')">VCenter 3</button>
                </mat-action-list>
            </mat-card-content>
        </mat-card>
        <mat-card class="mat-elevation-z8">
            <mat-card-title>HyperV</mat-card-title>
            <mat-card-content>
                <mat-action-list>
                    <button mat-list-item (click)="optimizerClicked('vmware', 'VCenter1')">HyperV 1</button>
                    <button mat-list-item (click)="optimizerClicked('vmware', 'VCenter2')">HyperV 2</button>
                </mat-action-list>
            </mat-card-content>
        </mat-card>
    </div>
</div>
