import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class DateService {
	private monthMap: { value: string, name: Month }[] = [
		{ value: '01', name: 'January' },
		{ value: '02', name: 'February' },
		{ value: '03', name: 'March' },
		{ value: '04', name: 'April' },
		{ value: '05', name: 'May' },
		{ value: '06', name: 'June' },
		{ value: '07', name: 'July' },
		{ value: '08', name: 'August' },
		{ value: '09', name: 'September' },
		{ value: '10', name: 'October' },
		{ value: '11', name: 'November' },
		{ value: '12', name: 'December' }
	];
	private month: string;
	private year: number;

	private date = new Date();

	constructor() {
		console.log(this.date.getMonth());
		this.month = this.getCurrentMonth();
		this.year = this.getCurrentYear();
	}

	public getMonth(): string {
		return this.month;
	}

	public setMonth(monthNumber: string): void {
		this.month = monthNumber;
	}

	public getYear(): number {
		return this.year;
	}

	public setYear(year: number): void {
		this.year = year;
	}

	public getNumberFromMonth(monthName: Month): string {
		return this.monthMap.filter((entry) => entry.name === monthName)[0].value;
	}

	public getMonthFromNumber(monthNumber: string): Month {
		return this.monthMap.filter((entry) => entry.value === monthNumber)[0].name;
	}

	public getCurrentMonth(): string {
		// Date.getMonth() is 0-based!
		return (this.date.getMonth() + 1).toString().padStart(2, '0');
	}

	public getCurrentMonthName(): string {
		return this.date.toLocaleString('default', { month: 'long' });
	}

	public getCurrentYear(): number {
		console.log('Current Year: ' + this.date.getFullYear());
		return this.date.getFullYear();
	}

}

export type Month = 'January' | 'February' | 'March' | 'April' | 'May' | 'June' | 'July' | 'August' | 'September' | 'October' | 'November' | 'December';
export type Year = 2020 | 2021;
