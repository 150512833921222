import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Customer, CustomerService } from '../../services/customer.service';

@Component({
	selector: 'dl-customer-list',
	templateUrl: './customer-list.component.html',
	styleUrls: ['./customer-list.component.scss']
})
export class CustomerListComponent implements OnInit, AfterViewInit {
	public customers: Customer[];
	public isLoading: boolean;

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private formBuilder: FormBuilder,
		private customerService: CustomerService
	) {
		this.customerService.isLoading.subscribe((next) => (this.isLoading = next));
	}

	async ngOnInit(): Promise<void> {
		this.customers = await this.customerService.getAllCustomers();
	}

	async viewCustomer(customer: Customer): Promise<void> {
		await this.router.navigate(['view'], { relativeTo: this.route.parent, queryParams: { customer: customer.Id } });
	}

	ngAfterViewInit(): void {}
}
