<div class="spla-config-container">
	<div class="spacer" fxLayout="column" fxLayoutGap="10px">
		<mat-card class="mat-elevation-z8">
			<mat-card-title>Product: {{ product }}</mat-card-title>
			<mat-card-content>
				<mat-horizontal-stepper linear #stepper (selectionChange)="onStepperUpdate($event)">
					<mat-step [stepControl]="contractForm">
						<form [formGroup]="contractForm">
							<ng-template matStepLabel>Vertragsinformationen</ng-template>
							<div fxLayout="column" fxLayoutGap="20px">
								<mat-form-field>
									<mat-label>Kundenname</mat-label>
									<mat-select formControlName="customer" placeholder="Kunde">
										<mat-option *ngFor="let customer of customers" value="{{ customer.name }}">
											{{ customer.longname }}</mat-option>
									</mat-select>
								</mat-form-field>
								<mat-form-field>
									<mat-label>Vertrag</mat-label>
									<mat-select formControlName="contract" placeholder="Vertrag">
										<mat-option *ngFor="let contract of contracts" value="{{ contract.name }}">
											{{ contract.name }}</mat-option>
									</mat-select>
								</mat-form-field>
							</div>

							<div>
								<button mat-stroked-button style="float: right; margin-top: 10px"
									matStepperNext>Next</button>
							</div>
						</form>
					</mat-step>
					<mat-step [stepControl]="productConfigForm">
						<form [formGroup]="productConfigForm">
							<ng-template matStepLabel>Produkt Konfiguration</ng-template>
							<div fxFill fxLayout="column" fxLayoutGap="20px">
								<mat-form-field fxFlex="0 0 auto">
									<mat-label>Manuelle Positionen (Zahl)</mat-label>
									<input matInput formControlName="manual" placeholder="0" required>
								</mat-form-field>
								<mat-form-field>
									<mat-label>Beistellungen (Zahl)</mat-label>
									<input matInput formControlName="beistellungen" placeholder="0" required>
								</mat-form-field>

								<mat-checkbox formControlName="reportMicrosoft">
									Report an Microsoft
								</mat-checkbox>
								<mat-checkbox formControlName="reportCustomer">
									Report an Kunden
								</mat-checkbox>
								<mat-form-field>
									<mat-label>Messmethode (auswählen für weitere Optionen)</mat-label>
									<mat-select formControlName="measurementMethod"
										placeholder="Select a measurement method"
										(selectionChange)="changeMeasurementMethod($event)">
										<mat-option value="usemanual">Übernimm manuellen Wert von oben</mat-option>
										<mat-option *ngFor="let method of measurementMethod" value="{{ method }}">
											{{ method }}</mat-option>
									</mat-select>
								</mat-form-field>
								<ng-container *ngIf="!!measurementInfo">
									{{ measurementInfo.description }}
									<div fxLayout="column" fxLayoutGap="20px" formGroupName="measurementConfig">
										<ng-container *ngFor="let config of measurementInfo.schema">
											<ng-container *ngIf="config.type === 'string'">
												<mat-form-field>
													<mat-label>{{ config.readable }}</mat-label>
													<input matInput formControlName="{{ config.name }}" required>
												</mat-form-field>
											</ng-container>
										</ng-container>
									</div>
								</ng-container>
								<div *ngIf="productConfigForm.get('measurementMethod').value == 'usemanual'">
									Übernimm den manuellen Wert, der zuvor festgelegt wurde.
								</div>
								<div>
									<button mat-button matStepperPrevious>Back</button>
									<button mat-button matStepperNext>Next</button>
								</div>
							</div>

						</form>
					</mat-step>
					<mat-step>
						<ng-template matStepLabel>Review</ng-template>
						<p>You are now done.</p>
						<div>
							<button mat-button matStepperPrevious>Back</button>
							<button mat-button (click)="stepper.reset()">Reset</button>
							<button mat-stroked-button color="accent" (click)="applyChanges()">Apply changes</button>
						</div>
					</mat-step>
				</mat-horizontal-stepper>
			</mat-card-content>
		</mat-card>
	</div>
</div>