import { Component, OnInit } from '@angular/core';
import {
	FormControl,
	FormGroup,
	FormGroupDirective,
	NgForm,
	ValidationErrors,
	ValidatorFn,
	Validators
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../services/authservice/auth.service';
import { WarningDialogComponent } from '../../dialogs/warning-dialog/warning-dialog.component';

export const passwordMatchValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
	const password1 = control.get('password1').value;
	const password2 = control.get('password2').value;

	return password1 === password2 ? null : { passwordmatch: true };
};

class CrossFieldErrorMatcher implements ErrorStateMatcher {
	isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
		return control.dirty && form.invalid;
	}
}

@Component({
	selector: 'dl-forgot-password',
	templateUrl: './forgotpassword.component.html',
	styleUrls: ['./forgotpassword.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
	public changing: boolean = false;
	public verifyPassword = new CrossFieldErrorMatcher();

	public forgotPasswordForm = new FormGroup(
		{
			username: new FormControl('', [Validators.email]),
			code: new FormControl('', [Validators.pattern(/[0-9]{6}/g)]),
			password1: new FormControl('', [
				Validators.minLength(8),
				Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/g)
			]),
			password2: new FormControl('')
		},
		{ validators: passwordMatchValidator }
	);

	constructor(
		private dialog: MatDialog,
		private router: Router,
		private route: ActivatedRoute,
		private authService: AuthService
	) {}

	async ngOnInit(): Promise<void> {}

	public resendCode() {
		this.router.navigate(['requestNew'], { relativeTo: this.route.parent });
	}

	public backToLogin(): void {
		this.router.navigate(['login'], { relativeTo: this.route.parent });
	}

	public changePassword(): void {
		if (
			this.forgotPasswordForm.controls['password1'].value !== this.forgotPasswordForm.controls['password2'].value
		) {
			this.dialog.open(WarningDialogComponent, {
				width: '400px',
				data: {
					description: 'Passwords do not match'
				}
			});
			return;
		}

		this.authService.Auth.forgotPasswordSubmit(
			this.forgotPasswordForm.get('username').value,
			this.forgotPasswordForm.get('code').value,
			this.forgotPasswordForm.get('password1').value
		)
			.then(() => {
				this.router.navigate(['login'], { relativeTo: this.route.parent });
			})
			.catch((err) => {
				this.dialog.open(WarningDialogComponent, {
					width: '400px',
					data: {
						description: err.message
					}
				});
			});
	}
}
