import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './components/auth/auth.guard';
import { AuthorizationComponent } from './components/auth/authorization/authorization.component';
import { ForgotPasswordComponent } from './components/auth/forgot/forgotpassword.component';
import { LoginComponent } from './components/auth/login/login.component';
import { NewPasswordComponent } from './components/auth/newpassword/newpassword.component';
import { RequestNewPasswordComponent } from './components/auth/request-new/request.newpassword.component';
import { LayoutComponent } from './components/layout/layout.component';

const routes: Routes = [
	{
		path: 'auth',
		component: AuthorizationComponent,
		children: [
			{
				path: 'login',
				component: LoginComponent
			},
			{
				path: 'changePassword',
				component: NewPasswordComponent
			},
			{
				path: 'forgot',
				component: ForgotPasswordComponent
			},
			{
				path: 'requestNew',
				component: RequestNewPasswordComponent
			}
		]
	},
	{
		path: '',
		component: LayoutComponent,
		canActivate: [AuthGuard],
		children: [
			{
				path: 'data',
				loadChildren: () => import('./views/data/data.module').then((m) => m.DataModule)
			},
			{
				path: 'spla',
				loadChildren: () => import('./views/spla/spla.module').then((m) => m.SplaModule)
			},
			{
				path: 'ci',
				loadChildren: () =>
					import('./views/configuration-items/configuration-item.module').then(
						(m) => m.ConfigurationItemModule
					)
			},
			{
				path: 'reports',
				loadChildren: () => import('./views/reports/reports.module').then((m) => m.ReportsModule)
			},
			{
				path: 'customers',
				loadChildren: () => import('./views/customers/customers.module').then((m) => m.CustomersModule)
			},
			{
				path: 'grid-demo',
				loadChildren: () => import('./views/grid-demo/grid-demo.module').then((m) => m.GridDemoModule)
			}
		]
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
