<div class="spla-vm-details-container">
    <div class="spacer">
        <mat-card class="mat-elevation-z8">
            <mat-card-title> Dashboard > {{ type }} </mat-card-title>
            <mat-card-content style="padding-top: 10px;">
                <div #barchart></div>

                <mat-divider></mat-divider>
                
                <h2 style="padding: 20px 0px 10px 0px;">Host-VM license assignments</h2>
                <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree">
                   
                    <!-- This is the tree node template for leaf nodes -->
                    <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
                        <li class="mat-tree-node visualization-provider" [ngClass]="{'licensed': node.licensed, 'not-licensed': !node.licensed}" matRipple>
                            <!-- use a disabled button to provide padding for tree leaf -->
                            <button mat-icon-button disabled></button>
                            {{node.name}}
                        </li>
                    </mat-tree-node>
                    
                    <!-- This is the tree node template for expandable nodes -->
                    <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
                        <li [ngClass]="{'licensed': node.licensed, 'not-licensed': !node.licensed}">
                            <div class="mat-tree-node">
                                <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.name">
                                    <mat-icon class="mat-icon-rtl-mirror">
                                        {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                                    </mat-icon>
                                </button>
                                {{node.name}}
                            </div>
                            <ul [class.example-tree-invisible]="!treeControl.isExpanded(node)">
                                <ng-container matTreeNodeOutlet></ng-container>
                            </ul>
                        </li>
                    </mat-nested-tree-node>
                </mat-tree>
            </mat-card-content>
        </mat-card>
    </div>
</div>
