import { Injectable } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	CanActivate,
	CanActivateChild,
	Router,
	RouterStateSnapshot,
	UrlTree
} from '@angular/router';
import { AuthClass } from '@aws-amplify/auth/lib-esm/Auth';
import { Hub } from '@aws-amplify/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthService } from '../../services/authservice/auth.service';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
	private loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	private userName: BehaviorSubject<string> = new BehaviorSubject<string>('');

	constructor(private router: Router, private authService: AuthService) {
		const auth: AuthClass = authService.Auth;

		auth.currentUserPoolUser()
			.then((user) => {
				this.loggedIn.next(true);
				this.userName.next(user.getUsername());
			})
			.catch((_) => {
				this.loggedIn.next(false);
				this.userName.next('');
			});

		Hub.listen('auth', (data) => {
			console.log(data);
			if (data.payload.event === 'tokenRefresh') {
				console.log('Refresh');
				return;
			}

			this.loggedIn.next(data.payload.event === 'signIn' || (data.payload.event === 'tokenRefresh' && data.payload.message === 'New token retrieved'));
			if (data.payload.data && data.payload.data.username) {
				this.userName.next(data.payload.data.username);
			} else {
				this.userName.next('');
			}

			if (data.payload.event === 'signOut') {
				this.router.navigate(['auth', 'login']);
			}
		});
	}

	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		console.log('Activate');
		console.log(next);
		const auth: AuthClass = this.authService.Auth;
		return auth
			.currentUserPoolUser()
			.then((_) => true)
			.catch((_) => this.router.parseUrl('/auth/login'));
	}
	canActivateChild(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		console.log('ActivateChild');
		console.log(next);
		const auth: AuthClass = this.authService.Auth;
		return auth
			.currentUserPoolUser()
			.then((_) => true)
			.catch((_) => this.router.parseUrl('/auth/login'));
	}

	public get LoggedIn(): Observable<boolean> {
		return this.loggedIn.asObservable();
	}

	public get LoggedInSync(): boolean {
		return this.loggedIn.getValue();
	}

	public get UserName(): Observable<string> {
		return this.userName.asObservable();
	}
}
