<div class="order-form-default">
	<div class="spacer">
		<mat-card class="mat-elevation-z8">
			<mat-card-title>
				Licenses
			</mat-card-title>
			<mat-card-content>
				<table mat-table [dataSource]="license" style="width: 100%">
					<ng-container matColumnDef="name">
						<th mat-header-cell *matHeaderCellDef>Name</th>
						<td mat-cell *matCellDef="let element">{{ element.name }}</td>
					</ng-container>
					<ng-container matColumnDef="count">
						<th mat-header-cell *matHeaderCellDef>Count</th>
						<td mat-cell *matCellDef="let element">
							<div *ngIf="!isNumber(element.count)" style="color: red; font-weight: bold;">
								- No source available yet -
							</div>
							<div *ngIf="isNumber(element.count)">
								{{ element.count }}
							</div>

						</td>
					</ng-container>
					<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
					<tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="licenseClick(row.name)"
						class="table-row"></tr>
				</table>
			</mat-card-content>
		</mat-card>
	</div>
</div>