import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from 'src/app/services/apiservice/api.service';

@Injectable({
	providedIn: 'root'
})
export class CustomerService {
	public isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

	private isInitialized: boolean = false;
	private customers: { [id: string]: Customer } = {};

	constructor(private apiService: ApiService) {}

	async getAllCustomers(): Promise<Customer[]> {
		// if already downloaded, use cached version
		if (this.isInitialized) {
			return Object.values(this.customers);
		}

		// set loading indicator
		this.isLoading.next(true);

		// Query API
		const response = (await this.apiService.get<{}, Customer[]>('customers/list')).data;
		response.forEach((customer: Customer) => {
			this.customers[customer.Id] = customer;
		});
		this.isLoading.next(false);

		this.isInitialized = true;

		return response;
	}

	async getCustomerById(id: string): Promise<Customer> {
		if (!this.isInitialized) {
			await this.getAllCustomers();
		}

		return this.customers[id];
	}
}

export interface Customer {
	Id: string;
	Longname: string;
}
