import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatSpinner } from '@angular/material/progress-spinner';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiResponse, ApiService } from '../apiservice/api.service';

const waitEndpoint = 'athena-backend/waitforquery';

interface WaitForEndpointInput {
	queryid: string;
}

interface WaitForEndpointOutput {
	status?: 'running' | 'finished';
	error?: string;
}

const getResultsEndpoint = 'athena-backend/getqueryresultpage';

interface GetResultsEndpointInput {
	jwttoken: string;
	pagenumber: number;
}

interface GetResultsEndpointOutput<T> {
	header?: string[];
	results?: T[];
	error?: string;
}

interface EndpointQuery {
	name: string;
	jwttoken: string;
}

export interface SubmitQueryOutput {
	jwttoken?: string;
	error?: string;
}

async function delay(ms: number): Promise<void> {
	return new Promise(resolve => setTimeout(resolve, ms));
}

@Injectable({
	providedIn: 'root'
})
export class QueryService {

	private queries: EndpointQuery[] = [];

	private spinnerTopRef = this.cdkSpinnerCreate();
	private _isQueryRunning: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);


	constructor(private apiService: ApiService, private overlay: Overlay) {
		this.isQueryRunning.subscribe(
			(running: boolean) => {
				if (running) {
					this.showSpinner();
				} else {
					this.spinnerTopRef.hasAttached() ? this.stopSpinner() : null;
				}
			}
		);
	}

	async getData<T, I>(dataset: string, params?: I, page?: number): Promise<T[]> {
		if (this._isQueryRunning.value) {
			throw Error('Another query is already running');
		}

		this._isQueryRunning.next(true);

		// check if dataset is already queried
		const jwttoken = dataset + (params ? btoa(JSON.stringify(params)) : '');
		let query: EndpointQuery = this.queries.find(q => q.name === jwttoken);
		if (!query) {
			// Start new athena job
			let startJobRequest: ApiResponse<SubmitQueryOutput>;
			try {
				startJobRequest = await this.apiService.post<any, SubmitQueryOutput>(dataset);
			} catch (err) {
				this._isQueryRunning.next(false);
				throw Error(`Could not start retrieval for dataset ${dataset}. Does the dataset exist?`);
			}

			// and wait for the results
			try {
				do {
					const jobWaitResult = (await this.apiService.get<WaitForEndpointInput, WaitForEndpointOutput>(
						waitEndpoint,
						{
							jwttoken: startJobRequest.data.jwttoken
						}
					));

					if (jobWaitResult.data.status === 'finished') {
						break;
					}

					await delay(3000);
				} while (true);
			} catch (err) {
				this._isQueryRunning.next(false);
				throw Error(`Could not retrieve JobResultStatus for query ${startJobRequest.data}`);
			}


			// Finally, add index for this query to cache
			query = { name: jwttoken, jwttoken: startJobRequest.data.jwttoken };
			this.queries.push(query);
		}

		try {
			const results = await this.apiService.get<GetResultsEndpointInput, GetResultsEndpointOutput<T>>(
				getResultsEndpoint,
				{
					jwttoken: query.jwttoken,
					pagenumber: !!page ? page : 0
				}
			);

			this._isQueryRunning.next(false);
			return results.data.results;
		} catch (err) {
			this._isQueryRunning.next(false);
			throw Error(`Failed to retrieve query results for query with token ${query.jwttoken}`);
		}
	}

	private cdkSpinnerCreate(): OverlayRef {
		return this.overlay.create({
			hasBackdrop: true,
			backdropClass: 'dark-backdrop',
			positionStrategy: this.overlay.position()
				.global()
				.centerHorizontally()
				.centerVertically()
		});
	}

	private showSpinner(): void {
		this.spinnerTopRef.attach(new ComponentPortal(MatSpinner));
	}

	private stopSpinner(): void {
		this.spinnerTopRef.detach();
	}

	public get isQueryRunning(): Observable<boolean> {
		return this._isQueryRunning.asObservable();
	}
}
