import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AgGridModule } from 'ag-grid-angular';
import { LicenseManager } from 'ag-grid-enterprise';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuard } from './components/auth/auth.guard';
import { AuthorizationComponent } from './components/auth/authorization/authorization.component';
import { ChangePasswordComponent } from './components/auth/change/changepassword.component';
import { ForgotPasswordComponent } from './components/auth/forgot/forgotpassword.component';
import { LoginComponent } from './components/auth/login/login.component';
import { NewPasswordComponent } from './components/auth/newpassword/newpassword.component';
import { RequestNewPasswordComponent } from './components/auth/request-new/request.newpassword.component';
import { InputDialogComponent } from './components/dialogs/input-dialog/input-dialog.component';
import { WarningDialogComponent } from './components/dialogs/warning-dialog/warning-dialog.component';
import { LayoutComponent } from './components/layout/layout.component';
import { NavigationModule } from './components/navigation/navigation.module';
import { ApiService } from './services/apiservice/api.service';
import { AuthService } from './services/authservice/auth.service';
import { DateService } from './services/dateservice/date.service';
import { LicenseService } from './services/licenseservice/license.service';
import { LoadingIndicatorService } from './services/loading-indicator-service/loading-indicator.service';
import { QueryService } from './services/queryservice/query.service';
import { InfrastructureModule } from './views/data/infrastructure/infrastructure.module';
import { SplaModule } from './views/spla/spla.module';

LicenseManager.setLicenseKey('CompanyName=CANCOM Managed Services GmbH,LicensedGroup=ag-Grid-cc-MaSe_1 , ag-Grid-cc-MaSe_2,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=2,LicensedProductionInstancesCount=0,AssetReference=AG-013613,ExpiryDate=15_February_2022_[v2]_MTY0NDg4MzIwMDAwMA==22fdf444272b066ddc6027bd01fd84b5');

@NgModule({
	declarations: [
		AppComponent,
		AuthorizationComponent,
		InputDialogComponent,
		LayoutComponent,
		LoginComponent,
		NewPasswordComponent,
		WarningDialogComponent,
		ForgotPasswordComponent,
		RequestNewPasswordComponent,
		ChangePasswordComponent
	],
	imports: [
		AgGridModule.withComponents(),
		AppRoutingModule,
		BrowserAnimationsModule,
		BrowserModule,
		FlexLayoutModule,
		FormsModule,
		InfrastructureModule,
		MatButtonModule,
		MatCardModule,
		MatCheckboxModule,
		MatDialogModule,
		MatFormFieldModule,
		MatIconModule,
		MatSlideToggleModule,
		MatInputModule,
		MatMenuModule,
		MatProgressSpinnerModule,
		MatSidenavModule,
		MatToolbarModule,
		MatTooltipModule,
		MatSnackBarModule,
		NavigationModule,
		ReactiveFormsModule,
		SplaModule,
		NgbModule
	],
	providers: [ApiService, AuthGuard, AuthService, DateService, LicenseService, QueryService, LoadingIndicatorService],
	bootstrap: [AppComponent],
	entryComponents: [InputDialogComponent, LoginComponent, WarningDialogComponent, ChangePasswordComponent]
})
export class AppModule { }
