<div class="spla-dashboard-date-container">
	<div class="spacer" fxFlexFill fxLayout="column">
		<div fxLayout="column" fxLayoutGap="10px">
			<mat-card class="mat-elevation-z8" fxFlex="0 0 auto">
				<mat-card-title>DATE</mat-card-title>
				<mat-card-content fxLayout="row" fxLayoutGap="8px">
					<mat-select [(value)]="month">
						<mat-option *ngFor="let month of monthMap" value="{{ month.value }}">
							{{ month.name }}
						</mat-option>
					</mat-select>
					<mat-select [(value)]="year">
						<mat-option *ngFor="let year of years" value="{{ year }}">
							{{ year }}
						</mat-option>
					</mat-select>
				</mat-card-content>
			</mat-card>
		</div>
	</div>
</div>
<router-outlet></router-outlet>
