import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/apiservice/api.service';
import {
	AddReportDialogComponent,
	AddReportDialogResult
} from 'src/app/views/reports/components/dialogs/add-report/add-report.dialog';
import { ReportService } from 'src/app/views/reports/services/report.service';

@Component({
	selector: 'dl-customer-view',
	templateUrl: './customer-view.component.html',
	styleUrls: ['./customer-view.component.scss']
})
export class CustomerViewComponent implements OnInit, AfterViewInit {
	public customer: string;
	public reports: string[] = [];

	public isLoading: boolean = false;

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private apiService: ApiService,
		private reportService: ReportService,
		private dialog: MatDialog
	) {}

	async ngOnInit(): Promise<void> {
		this.route.queryParams.subscribe((params) => {
			this.customer = params.customer;
			this.getCustomerReports();
		});
	}

	async getCustomerReports(): Promise<void> {
		this.isLoading = true;

		// this.reports = (
		// 	await this.apiService.get<{ Customer: string }, { Name: string }[]>('ci/customer/reports/customer', {
		// 		Customer: this.customer
		// 	})
		// ).data.map((x) => x.Name);

		this.reports = (await this.reportService.getCustomerReports(this.customer)).map((report) => report.Name);

		this.isLoading = false;
	}

	async openReport(report: string): Promise<void> {
		await this.router.navigate(['reports', report, 'dashboard'], { queryParams: { customer: this.customer } });
	}

	async addReport(): Promise<void> {
		const dialogRef = this.dialog.open(AddReportDialogComponent, {
			width: '400px',
			data: { reports: this.reports, customer: this.customer }
		});

		dialogRef.afterClosed().subscribe((result: AddReportDialogResult) => {
			console.log(result);
			if (result.success) {
				this.getCustomerReports();
			}
		});
	}

	ngAfterViewInit(): void {}
}
